import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import Dashelement from "components/Dashboard/Dashelement";
import "./profileform.css";
import { BlankImg } from "assets/images";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";

const FormValidate = Yup.object().shape({
  about_me: Yup.string().required("This field is mandatory"),

  // profile_picture: Yup.files().required("is required"),

  social_selected: Yup.bool().isTrue(),
});

const ProfileInfo = (props) => {
  const [avatar, setAvatar] = useState(BlankImg);
  const [isEnabled, setIsEnabled] = useState(false);
  const handleSubmit = (values) => {
    props.next(values);
  };

  const getImage = (files) => {
    if (!files || files.length === 0) return; // Prevent crash if no file exists
    const file = files[0];
    const reader = new FileReader();
  
    reader.onload = (e) => {
      setAvatar(e.target.result);
      setIsEnabled(true);
    };
  
    reader.onerror = (e) => {
      console.error("Error reading file:", e);
    };
  
    reader.readAsDataURL(file);
  
  };

  return (
    <Formik
      initialValues={props.data}
      onSubmit={handleSubmit}
      validationSchema={FormValidate}
    >
      {({ values, touched, errors, setFieldValue, isValid }) => (
        <Form>
          <Dashelement className="features profile-info-form-first">
            <div className="container">
              <div className="information-text wrapper">
                <h3>Personal Information</h3>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6 mt-3 profile_picture_wrap">
                  <div className="form-outline">
                    <div>
                      <img src={avatar} className="img" alt="" />
                    </div>

                    {touched.profile_picture}
                    <ErrorMessage
                      component="div"
                      name="profile_picture"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 mt-3 d-flex profile_picture_upload_wrap justify-content-center">
                  <UploadImage
                    setFieldValue={setFieldValue}
                    value="imageFile"
                    class="uploader-parts"
                    title="Upload Your Photo"
                    maxSize={10000000}
                    body={
                      <div className="mb-3">
                        Requirements:{" "}
                        <ul>
                          <li>.jpg, .png, or .gif file type</li>
                          <li>less than 10MB</li>
                          <li>file name is less than 100 characters</li>
                        </ul>
                      </div>
                    }
                    footer="Click here or drag image here."
                    accept={{ "image/*": [] }}
                  />
                  {values.imageFile && getImage(values.imageFile)}
                </div>
              </div>
            </div>
          </Dashelement>
          <div className="button-place text-end">
            <button
              className={`${
                !isEnabled ? "btn btn-secondary btn-lg " : "btn btn-primary"
              }`}
              disabled={!isEnabled}
              type="submit"
            >
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const UploadImage = (props) => {
  const { setFieldValue } = props;
  const [errors, setErrors] = useState("");
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.accept,
    maxFiles: 1,
    multiple: false,
    maxSize: props.maxSize,
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles) {
        acceptedFiles.forEach((file) => {
          setErrors("");
          setFieldValue(`${props.value}`, acceptedFiles);
        });
      }
      if (fileRejections) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrors(`Error: File is larger than 10MB`);
            }

            if (err.code === "file-invalid-type") {
              setErrors(`Error: ${err.message}`);
            }
          });
        });
      }
    },
  });
  return (
    <div className="upload-field">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className={`${props.class} text-center`}>
            <div className="upload-text  justify-content-center">
              <h5>Drop the files here ...</h5>
            </div>
          </div>
        ) : (
          <div className={`${props.class} text-center`}>
            <div className="upload-text justify-content-center">
              <h5 className="mb-3">{props.title}</h5>

              {props.body}
              {errors === "" ? "" : <p style={{ color: "red" }}>{errors}</p>}
              <p className="mb-3" style={{ color: "#FF3030" }}>
                {props.footer}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileInfo;
