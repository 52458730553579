import TermsCondition from "components/Agreements/TermsCondition";
import NavComponent from "components/Navigation/NavComponent";
import AuthContext from "context/AuthContext";
import IncompleteProfile from "Pages/dashboard/Profile/IncompleteProfile";
import StoreUserProfile from "Pages/dashboard/Profile/StoreUserProfile";
import PlansSetup from "Pages/dashboard/PureBrand/Plans/PlansSetup";
import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import Gethelp from "./Gethelp";
import "./settings.css";
import UsefulLinks from "./UsefulLinks";
import Dots from "components/Dots/Dots";
import PrivacyPolShopify from "components/Agreements/PrivacyPolShopify";
import AdminDots from "components/AdminDots/AdminDots";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import StoreTermsCondition from "components/Agreements/StoreTermsCondition";

const UserSettings = () => {
  const { user, userProfile, isEmailVerified, emailVerificationLoading } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("help");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get("page");

    switch (page) {
      case "term":
        setActiveKey("termsconditions");
        break;
      case "help":
        setActiveKey("help");
        break;
      case "payout":
        if (user.role === 3) {
          setActiveKey("payout");
        }
        break;
      case "planSettings":
        if (user.role === 1) {
          setActiveKey("planSettings");
        }
        break;
      case "commissionSettings":
        if ([2, 4, 5, 6].includes(user.role)) {
          setActiveKey("commissionSettings");
        }
        break;
      case "usefulLinks":
        if ([2, 4, 5, 6].includes(user.role)) {
          setActiveKey("usefulLinks");
        }
        break;
      case "privacypolicy":
        setActiveKey("privacypolicy");
        break;
      case "adminDots":
        setActiveKey("adminDots");
        break;
      default:
        setActiveKey(`${user.role === 3 ? "payout" : "help"}`);
    }
  }, [location.search, user.role]);

  /**
   * This function is for handling the active key for tabs
   *
   * @param {void}
   * @return {void}
   */

  const handleSelect = (key) => {
    setActiveKey(key);
    let page = "help";
    switch (key) {
      case "termsconditions":
        page = "term";
        break;
      case "help":
        page = "help";
        break;
      case "payout":
        page = "payout";
        break;
      case "planSettings":
        page = "planSettings";
        break;
      case "commissionSettings":
        page = "commissionSettings";
        break;
      case "usefulLinks":
        page = "usefulLinks";
        break;
      case "privacypolicy":
        page = "privacypolicy";
        break;
      case "adminDots":
        page = "adminDots";
        break;
      default:
        page = `${user.role === 3 ? "payout" : "help"}`;
    }
    navigate(`${location.pathname}?page=${page}`);
  };

  if (emailVerificationLoading) {
    return <PurebrandLoader />;
  }

  // if (!userProfile?.complete_profile) {
  //   return <IncompleteProfile />;
  // } else
   if (!isEmailVerified && user?.role === 3) {
    return <></>
  } else {
    return (
      <>
        <Tabs
          activeKey={activeKey}
          onSelect={handleSelect}
          defaultActiveKey={`${user.role === 3 ? "payout" : "help"}`}
          id="uncontrolled-tab-example"
          className="mb-4"
        >
          {user.role === 1 ||
          user.role === 2 ||
          user.role === 4 ||
          user.role === 5 ||
          user.role === 6 ? (
            <></>
          ) : (
            <NavComponent
              eventKey="payout"
              title={
                <>
                  Configure Payment{" "}
                  <Tooltip
                    title={
                      "You will only see the list of stores for connecting in whom you have at least one approved product"
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
              }
              className="features settings-payout-wrapper"
            >
              {/* <Payoutmethod /> */}
              <>
                {/* <OnBoardHosts />
                <HostBankForm /> */}
                <div className="dots-container" style={{ textAlign: "center" }}>
                  {/* <button onClick={() => {
                  handleDotsConnect();
                }} style={{textAlign: 'center'}}>Connect with dots</button> */}
                  <Dots />
                </div>
              </>
            </NavComponent>
          )}
          <NavComponent
            eventKey="help"
            title="Get Help"
            className="features settings-help-wrapper"
          >
            <Gethelp />
          </NavComponent>
          {user.role === 1 ? (
            <NavComponent
              eventKey="planSettings"
              title="Set Plans"
              className="features settings-plans-wrapper"
            >
              <PlansSetup />
            </NavComponent>
          ) : null}
          {user.role === 2 ||
          user.role === 4 ||
          user.role === 5 ||
          user.role === 6 ? (
            <NavComponent
              eventKey="commissionSettings"
              title="Set Commission"
              className="features settings-plans-wrapper"
            >
              <StoreUserProfile />
            </NavComponent>
          ) : null}
          {user.role === 2 ? (
            <NavComponent
              eventKey="adminDots"
              title="Payment Setup"
              className="features settings-plans-wrapper"
            >
              <AdminDots />
            </NavComponent>
          ) : null}
          {user.role === 2 ||
          user.role === 4 ||
          user.role === 5 ||
          user.role === 6 ? (
            <NavComponent
              eventKey="usefulLinks"
              title="Helpful Links"
              className="features settings-plans-wrapper"
            >
              <UsefulLinks />
            </NavComponent>
          ) : null}
          <NavComponent
            eventKey="termsconditions"
            title="Terms & Conditions"
            className="features settings-tandc-wrapper"
          >
            {
              user?.role === 3 ? <> <TermsCondition /></> : <><StoreTermsCondition/></>
            }
          </NavComponent>
          <NavComponent
            eventKey="privacypolicy"
            title="Privacy Policy"
            className="features settings-privacy-wrapper"
          >
            {/* <PrivacyPol /> */}
            <PrivacyPolShopify />
          </NavComponent>
        </Tabs>
      </>
    );
  }
};

export default UserSettings;
