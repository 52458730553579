import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Form,
  InputNumber,
  Select,
  Input,
  Col,
  Row,
  DatePicker,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import './ComplianceModal.css';
import { MESSAGES } from './constants/messages';
import { PLACEHOLDERS } from './constants/placeholders';
import moment from 'moment';
import {
  COUNTRY_OPTIONS,
  GENERIC_ERROR_MESSAGE,
  INCORPORATION_TYPES,
  US_STATE_LIST,
} from 'utils/constants';
import DirectorModal from './DirectorModal';
import PhoneInput from 'react-phone-input-2';
import { addComplianceInfo } from 'Services/dotsService';
import DotsContext from 'context/DotsContext';
import ProfileContext from 'context/ProfileContext';
import { useNavigate, useLocation } from 'react-router-dom';

const { Option } = Select;
const { TextArea } = Input;

/**
 * This component renders the inputs for entering compliance information
 *
 * @return {ReactNode} A React element that renders the inputs for entering compliance information
 */

const ComplianceModal = ({
  complianceModalOpen,
  handleModalClose,
  directors,
  setDirectors,
  directorsModalOpen,
  setDirectorsModalOpen,
  getStoreAdminsStatus,
  initialStore,
  existingUser
}) => {

  const [form] = Form.useForm();
  const {state} = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const { updateFormData } = useContext(ProfileContext);
  const { dotsStoreComplianceInfo } = useContext(DotsContext);

  const [currentDirectorIndex, setCurrentDirectorIndex] = useState();
  const [addComplianceLoading, setComplianceLoading] = useState(false);
  const navigate = useNavigate();
  const onFinish = async (values) => {
    if (values?.company_info?.ubo_count !== directors.length) {
      messageApi.open({
        type: 'error',
        content: 'UBO count must be equal to the number of owners',
        duration: 5,
      });
      return;
    }
    // send this shopname in navigation state instead of updating the localstorage. Check if the formdata context has the shopname other wise use from localstorage.
    updateFormData({ shopName: values?.company_info?.name });

    setComplianceLoading(true);

    const payload = { ...values };
    payload['directors'] = [...directors];

    payload.company_info.incorporation_date = moment(
      payload?.company_info?.incorporation_date
    ).format('YYYY-MM-DD');

    payload['directors'].forEach((director, index) => {
      payload['directors'][index].dob = dayjs(director?.dob).format(
        'YYYY-MM-DD'
      );
      payload['directors'][index].ssn = director?.ssn?.toString();
    });

    try {
      const response = await addComplianceInfo(payload);
      messageApi.open({
        type: 'success',
        content: 'Successfully submitted compliance information',
      });
      setComplianceLoading(false);
      // This condition is not enough, I need to check if I have been redirected 
      if(!existingUser?.complete_profile && state?.completeProfileFlow &&  response.data.message === "success"){
        // create a timeout event to navigate to the next page after 3 seconds
        setTimeout(() => {
          navigate("/user/completeprofile", { state: { resumeStep: 3, shopName: values?.company_info?.name } });
        }, 3000);
      }
      handleModalClose();
      getStoreAdminsStatus();
    } catch (e) {
      console.log(e);
      setComplianceLoading(false);
      messageApi.open({
        type: 'error',
        content:
          e?.response?.data?.message || e?.message || GENERIC_ERROR_MESSAGE,
        duration: 5,
      });
    }
  };

  const handleDirectorsModalClose = () => {
    setDirectorsModalOpen(false);
    setCurrentDirectorIndex(undefined);
  };

  const handleDeleteDirector = (index) => {
    const updatedDirectors = directors.filter((_, i) => i !== index);
    setDirectors(updatedDirectors);
  };

  /**
   * This function is to get the disability of the dates in the date picker
   *
   * @return {Array} disabled dates
   */

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  useEffect(() => {
    if (currentDirectorIndex !== undefined) {
      setDirectorsModalOpen(true);
    }
  }, [currentDirectorIndex]);

  useEffect(() => {
    const newDotsStoreComplianceInfo = { ...dotsStoreComplianceInfo };
    if (newDotsStoreComplianceInfo?.company_info?.incorporation_date) {
      newDotsStoreComplianceInfo.company_info.incorporation_date = dayjs(
        newDotsStoreComplianceInfo?.company_info?.incorporation_date
      );
    }
    form.setFieldsValue(newDotsStoreComplianceInfo);
  }, [dotsStoreComplianceInfo]);

  return (
    <>
      {' '}
      {contextHolder}
      <div className="compliance-modal">
        <Form
          layout={'vertical'}
          form={form}
          onFinish={onFinish}
          initialValues={{
            company_info: {
              name: initialStore,
            },
            flow_of_funds: {
              reasons: 'Payment to affiliates for demoing products on the Purebrand.io platform.',
            },
          }}
        >
          <div className="company-information">
            <h3>Company Information:</h3>
            <Row>
              <Col className="custom-col">
                <Form.Item
                  name={['company_info', 'name']}
                  label="Company Name"
                  rules={[
                    { required: true, message: MESSAGES.COMPANY_NAME_REQUIRED },
                  ]}
                >
                  <Input placeholder={PLACEHOLDERS.COMPANY_NAME} />
                </Form.Item>
              </Col>
              <Col className="custom-col">
                <Form.Item
                  name={['company_info', 'website']}
                  label="Company Website"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.COMPANY_WEBSITE_REQUIRED,
                    },
                  ]}
                >
                  <Input placeholder={PLACEHOLDERS.COMPANY_WEBSITE} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="custom-col">
                <Form.Item name={['company_info', 'dba']} label="Company DBA">
                  <Input placeholder={PLACEHOLDERS.COMPANY_DBA} />
                </Form.Item>
              </Col>
              <Col className="custom-col">
                <Form.Item
                  name={['company_info', 'ein']}
                  label="Tax ID"
                  rules={[
                    { required: true, message: MESSAGES.COMPANY_TAX_REQUIRED },
                  ]}
                >
                  <Input placeholder={PLACEHOLDERS.TAX_ID} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="custom-col">
                <Form.Item
                  name={['company_info', 'incorporation_type']}
                  label="Incorporation Type"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.INCORPORATION_TYPE_REQUIRED,
                    },
                  ]}
                >
                  <Select placeholder={PLACEHOLDERS.INCORPORATION_TYPE}>
                    {INCORPORATION_TYPES.map((type) => (
                      <Option key={type.value} value={type.value}>
                        {type.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="custom-col">
                <Form.Item
                  name={['company_info', 'incorporation_state']}
                  label="Incorporation State"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.INCORPORATION_STATE_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={PLACEHOLDERS.INCORPORATION_STATE}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label.toLocaleLowerCase() ?? '').includes(
                        input.toLocaleLowerCase()
                      )
                    }
                    options={US_STATE_LIST}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="custom-col">
                <Form.Item
                  name={['company_info', 'incorporation_date']}
                  label="Incorporation Date"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.INCORPORATION_DATE_REQUIRED,
                    },
                  ]}
                >
                  <DatePicker
                    placeholderText={PLACEHOLDERS.INCORPORATION_DATE}
                    // dateFormat={'yyyy-MM-dd'}
                    className="popup-calendar"
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="owners-information">
            <h3>
              Ultimate Beneficial Owners{' '}
              <span className="learn-more-ubo">
                (Learn more about UBO{' '}
                <a
                  href="https://www.swift.com/your-needs/financial-crime-cyber-security/know-your-customer-kyc/ultimate-beneficial-owner-ubo"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                )
              </span>{' '}
              {'  '}:{' '}
            </h3>
            <Row>
              <Col className="custom-col">
                <Form.Item
                  name={['company_info', 'ubo_count']}
                  label="UBO Count"
                  rules={[
                    { required: true, message: MESSAGES.UBO_COUNT_REQUIRED },
                  ]}
                >
                  <InputNumber min={1} max={10} />
                </Form.Item>
              </Col>
            </Row>

            {directors.map((director, index) => {
              return (
                <div className="director-section" key={index}>
                  <div className="director-first-name">
                    {director?.first_name} {director?.last_name}
                  </div>
                  <div className="actions">
                    <EditOutlined
                      className="edit-btn"
                      onClick={() => {
                        setCurrentDirectorIndex(index);
                      }}
                    />
                    <DeleteOutlined
                      className="delete-btn"
                      onClick={() => handleDeleteDirector(index)}
                    />
                  </div>
                </div>
              );
            })}

            <Button
              onClick={() => {
                setDirectorsModalOpen(true);
              }}
            >
              Add new UBO
            </Button>
          </div>

          <div className="company-contact-information">
            <h3>Company Contact Information:</h3>
            <Row>
              <Col className="custom-col">
                <Form.Item
                  name={['contact_info', 'email']}
                  label="Email"
                  rules={[{ required: true, message: MESSAGES.EMAIL_REQUIRED }]}
                >
                  <Input placeholder={PLACEHOLDERS.EMAIL} />
                </Form.Item>
              </Col>
              <Col className="custom-col">
                <Form.Item
                  name={['contact_info', 'phone_number']}
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.PHONE_REQUIRED,
                    },
                  ]}
                >
                  <PhoneInput
                    country={'us'}
                    disableDropdown
                    countryCodeEditable={false}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="custom-col">
                <Form.Item
                  name={['contact_info', 'line1']}
                  label="Line 1"
                  rules={[{ required: true, message: MESSAGES.LINE1_REQUIRED }]}
                >
                  <Input placeholder={PLACEHOLDERS.LINE1} />
                </Form.Item>
              </Col>
              <Col className="custom-col">
                <Form.Item name={['contact_info', 'line2']} label="Line 2">
                  <Input placeholder={PLACEHOLDERS.LINE2} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="custom-col">
                <Form.Item
                  name={['contact_info', 'city']}
                  label="City"
                  rules={[{ required: true, message: MESSAGES.CITY_REQUIRED }]}
                >
                  <Input placeholder={PLACEHOLDERS.CITY} />
                </Form.Item>
              </Col>
              <Col className="custom-col">
                <Form.Item
                  name={['contact_info', 'zip']}
                  label="Postal code"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.ZIP_REQUIRED,
                    },
                  ]}
                >
                  <Input placeholder={PLACEHOLDERS.ZIP} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="custom-col">
                <Form.Item
                  name={['contact_info', 'state']}
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.STATE_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={PLACEHOLDERS.INCORPORATION_STATE}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label.toLocaleLowerCase() ?? '').includes(
                        input.toLocaleLowerCase()
                      )
                    }
                    options={US_STATE_LIST}
                  />
                </Form.Item>
              </Col>
              <Col className="custom-col">
                <Form.Item
                  name={['contact_info', 'country']}
                  label="Country"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.COUNTRY_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    placeholder={PLACEHOLDERS.COUNTRY}
                    options={COUNTRY_OPTIONS}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="flow-of-funds">
            <h3>Flow of funds:</h3>
            <Row>
              <Col className="custom-col-funds">
                <Form.Item
                  name={['flow_of_funds', 'usage']}
                  label="Please describe your business"
                  rules={[{ required: true, message: MESSAGES.USAGE_REQUIRED }]}
                >
                  <TextArea rows={6} placeholder={PLACEHOLDERS.USAGE} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {' '}
              <Col className="custom-col-funds">
                <Form.Item
                  name={['flow_of_funds', 'reasons']}
                  label="Please describe the flow of funds"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.REASONS_REQUIRED,
                    },
                  ]}
                >
                  <Input.TextArea rows={4} placeholder={PLACEHOLDERS.REASONS} />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Form.Item>
            <Button
              className="cancel-compliance-info"
              onClick={() => {
                handleModalClose();
              }}
              disabled={addComplianceLoading}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-compliance-info"
              disabled={addComplianceLoading}
              loading={addComplianceLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>

        <DirectorModal
          directorsModalOpen={directorsModalOpen}
          handleModalClose={handleDirectorsModalClose}
          setDirectors={setDirectors}
          directors={directors}
          currentDirectorIndex={currentDirectorIndex}
        />
      </div>
    </>
  );
};

export default ComplianceModal;
