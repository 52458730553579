import React, { useContext, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import ProfileContext from "context/ProfileContext";
import Dashelement from "components/Dashboard/Dashelement";
import { UploadImage } from "../ProfileCompletion/ProfileInfo";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import ReactPlayer from "react-player";
import { FeatherImg } from "assets/images";
// Import both your old and new CSS
import "../ProfileCompletion/profileform.css";
import "./newUi.css";
import { useLocation } from "react-router-dom";

const schema = yup.object().shape({
  shopName: yup.string().required("required"),
  shipDate: yup
    .number()
    .typeError("Please enter a valid number")
    .required("required"),
  files: yup.mixed().required("required"),
  videofile: yup.mixed().required("required"),
});

const ShopifyDetail = (props) => {
  const { putToS3, setFormDataState, formDataState } =
    useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState();
  const [videoFileUpload, setVideoFileUpload] = useState(null);
  const [scale, setScale] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const createInitialFormData = () => {
    // Start with defaults (you can adjust these defaults as needed)
    let formData = {
      shopName: "",
      shipDate: "",
      files: null,
      videofile: null,
    };

    // Merge any existing props.data into the default object
    formData = { ...formData };
    // If localStorage contains a userProfile and it has an initialStoreName, override shopName
    if(location.state?.shopName){
      formData.shopName = location.state?.shopName;

    }else{
      const userProfile = localStorage.getItem("userProfile");
      if (userProfile) {
        const parsedProfile = JSON.parse(userProfile);
        if (parsedProfile.store?.store_name) {
          formData.shopName = parsedProfile.store?.store_name;
        }
        else{
          formData.shopName = formDataState.shopName;
        }
      }
  }

    return formData;
  };
  const location = useLocation();
  // Usage inside your component (for example, in a useEffect or when setting initialValues):
  const initialFormData = createInitialFormData();
  // Update scale and device type on resize
  useEffect(() => {
    const updateScaleAndDevice = () => {
      const windowWidth = window.innerWidth;
      let newScale = 1;
      if (windowWidth < 768) {
        newScale = 1.2;
      } else if (windowWidth < 1200) {
        newScale = 1.5;
      } else {
        newScale = 2;
      }
      setScale(newScale);
      setIsMobile(windowWidth < 768);
      // If formDataState isn’t already set, try to retrieve from localStorage or API
      if (!formDataState.shopName) {
        const userProfile = localStorage.getItem("userProfile");
        if (userProfile) {
          const parsedProfile = JSON.parse(userProfile);
          setFormDataState((prev) => ({
            ...prev,
            ...parsedProfile,
            // If parsedProfile has an initialStoreName, use it for shopName.
            // Otherwise, keep the existing shopName (or parsedProfile.shopName if available).
            shopName: parsedProfile.initialStoreName,
          }));
        }
        // Otherwise, you might call your API to fetch the user profile.
      }
    };

    updateScaleAndDevice();
    window.addEventListener("resize", updateScaleAndDevice);
    return () => window.removeEventListener("resize", updateScaleAndDevice);
  }, []);

  // Define the small video style based on screen width
  const smallVideoStyle = isMobile
    ? {
        position: "absolute",
        bottom: "17%",
        left: "40%",
        transform: `translateX(-50%) rotate(6.7deg) scale(${scale})`,
        width: "80px",
        height: "60px",
      }
    : window.innerWidth < 1200
    ? {
        position: "absolute",
        bottom: "20%",
        left: "38%",
        transform: `translateX(-50%) rotate(6.7deg) scale(${scale})`,
        width: "80px",
        height: "60px",
      }
    : {
        position: "absolute",
        bottom: "20%",
        left: "33%",
        transform: `rotate(6.7deg) scale(${scale})`,
        width: "80px",
        height: "60px",
      };

  // Handle form submission
  const handleSubmit = async (values) => {
    setLoading(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'  // Use 'auto' for instant scrolling
    });
    const imageData = {
      tag: "product_photo",
      type: "image",
      extension: "jpg",
      file: values.files,
    };
    const videoData = {
      tag: "Store_video",
      type: "video",
      extension: "mp4",
      file: values.videofile,
    };

    try {
      const resVideo = await putToS3(videoData);
      const resImg = await putToS3(imageData);
      if (resVideo.status === 200 && resImg.status === 200) {
        setLoading(false);
        values.files = resImg.data.data.id;
        values.videofile = resVideo.data.data.id;
        // Store submitted data into context (for preview)
        setFormDataState(values);
        // Proceed to next step (for example, showing preview)
        props.next(values);
      }
    } catch (err) {
      console.error("Error uploading files", err);
      setLoading(false);
    }
  };

  const getImage = (files) => {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setAvatar(e.target.result);
    };
  };

  const handleVideoChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setVideoFileUpload(URL.createObjectURL(file));
    }
  };

  return (
    <>
      {loading && (
        <PurebrandLoader
          textMessage='Uploading'
          colorCode='rgba(248, 248, 248, 0.7)'
        />
      )}
      {/* =================== FORM SECTION =================== */}
      <Formik
        initialValues={initialFormData}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ values, touched, errors, setFieldValue }) => (
          <Form>
            <Dashelement className='features basic-info-wrapper'>
              <div className='container'>
                <div className='row'>
                  <div className='information-text-wrapper'>
                    <h3>More info on your store</h3>
                    <p>Please fill in the following details for your store.</p>
                  </div>
                  {/* Left Column: store name, shipping days */}
                  <div className='col col-sm-12 col-md-4 col-lg-4'>
                    <div className='row'>
                      <div className='col-12 col-md-12'>
                        <Field
                          placeholder='Store Name (Eg. MurphyDoor Inc)'
                          type='text'
                          name='shopName'
                          className={`form-control ${
                            touched.shopName && errors.shopName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component='div'
                          name='shopName'
                          className='invalid-feedback'
                        />
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <p>
                        No. of days after an order is shipped to invite a
                        customer to start hosting on the Purebrand platform.
                      </p>
                      <div className='col-12 col-md-12'>
                        <Field
                          placeholder='Days (Eg. 5)'
                          type='number'
                          name='shipDate'
                          className={`form-control ${
                            touched.shipDate && errors.shipDate
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component='div'
                          name='shipDate'
                          className='invalid-feedback'
                        />
                      </div>
                    </div>
                  </div>
                  {/* Middle Column: Upload store logo */}
                  <div className='col col-sm-12 col-md-4 col-lg-4'>
                    <UploadImage
                      setFieldValue={setFieldValue}
                      value='files'
                      class='uploader-parts'
                      title='Upload Your Store Logo'
                      maxSize={10000000}
                      body={
                        !avatar ? (
                          <div className='mb-3'>
                            Requirements:
                            <ul>
                              <li>.jpg, .png, or .gif file type</li>
                              <li>less than 10MB</li>
                              <li>file name is less than 100 characters</li>
                            </ul>
                          </div>
                        ) : (
                          <div>
                            <img
                              src={avatar}
                              className='img img_small_viewfinder'
                              alt='Store Logo'
                            />
                          </div>
                        )
                      }
                      footer='Click here or drag image here.'
                      accept={{ "image/*": [] }}
                    />
                    {values.files && getImage(values.files)}
                  </div>
                  {/* Right Column: Upload store video */}
                  <div className='col col-sm-12 col-md-4 col-lg-4'>
                    <div className='uploader-parts' style={{ height: "auto" }}>
                      <h5 className='upload-text'>Upload and Preview Video</h5>
                      <div className='video-uploader-container'>
                        <input
                          type='file'
                          accept='video/*'
                          onChange={(event) => {
                            setFieldValue(
                              "videofile",
                              event.currentTarget.files
                            );
                            handleVideoChange(event);
                          }}
                          className='btn btn-upload-video w-100 p-2'
                        />
                        {videoFileUpload ? (
                          <div>
                            <div className='upload-text'>
                              <ReactPlayer
                                url={videoFileUpload}
                                controls
                                autoPlay
                                loop
                                muted
                                width='100%'
                                height='auto'
                                style={{ objectFit: "cover" }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className='uploader-part-video'>
                            <div className='upload-text'>
                              Click the button above to select a video.
                              <br />
                              {/* <div className="mb-3"> */}
                              Requirements:
                              <ul>
                                <li> .mp4, .mov, .avi file type</li>
                                <li>less than 100MB</li>
                                <li>file name is less than 100 characters</li>
                                <li>
                                  make sure the video is in potrate mode and
                                  should fit this preview screen.
                                </li>
                              </ul>
                              {/* </div> */}
                              <img
                                src={FeatherImg}
                                alt='Feather'
                                width='100px'
                                className='upload-text'
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dashelement>
            <div className='button-place text-end'>
              <button className='btn btn-primary' type='submit'>
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>

      {/* =================== HERO SECTION (Angled Phone) =================== */}
      <div
        className='pb-dev-demo-img-text'
        style={{ background: "#fff", padding: "50px 0", marginTop: "40px" }}
      >
        <div className='pb-dev-demo-container'>
          <div className='pb-dev-demo-img-row'>
            {/* Left Column: Title & Subheading */}
            <div className='pb-dev-demo-left-col'>
              <div className='pb-dev-demo-left-inner'>
                <h2 className='pb-dev-demo-title'>Your questions, answered.</h2>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='pb-dev-svg-for-round'
                  viewBox='0 0 507 33'
                  fill='none'
                >
                  <path
                    d='M4 4C76.538 22.8656 277.891 49.2774 503 4'
                    stroke='#23265F'
                    strokeWidth='8'
                    strokeLinecap='round'
                  />
                </svg>
                <p className='pb-dev-demo-contentss'>
                  Murphy Door has partnered with Purebrand to bring you demos
                  with real customers and see our products in action!
                </p>
              </div>
            </div>

            {/* Right Column: The angled phone container */}
            <div className='pb-dev-demo-right-col'>
              <div className='pb-dev-demo-right-inner pb-dev-container-image'>
                <div className='pb-dev-video-main-box'>
                  <div className='pb-dev-video-inner-box'>
                    {videoFileUpload ? (
                      <ReactPlayer
                        url={videoFileUpload}
                        // controls
                        playing
                        autoPlay
                        loop
                        muted
                        width='100%'
                        height='100%'
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          background: "#fff",
                        }}
                      />
                    )}
                  </div>

                  <div className='small-video-preview' style={smallVideoStyle}>
                    <ReactPlayer
                      url='https://cdn.shopify.com/videos/c/o/v/5d524bf539fa466589613c635bcb91d6.mp4'
                      playing
                      muted
                      loop
                      width='100%'
                      height='100%'
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =================== REVIEWS SECTION =================== */}
      <div
        className='pb-dev-demo-review'
        style={{ background: "#fff", padding: "50px 0" }}
      >
        <div
          className='pb-dev-demo-review-container'
          style={{ maxWidth: "80%" }}
        >
          <div className='pb-dev-demo-desk-img'>
            {/* Use a demo image from state or a default */}
            <img
              src={
                formDataState.demo_image ||
                "https://via.placeholder.com/600x400"
              }
              alt='Review'
            />
          </div>
          <div className='pb-dev-demo-mobile-review'>
            <div className='pb-dev-demo-mobil-title'>
              {formDataState.demo_title || "How do the reviews work?"}
            </div>
            <div className='pb-dev-demo-mobil-infos'>
              {formDataState.demo_info ||
                "Our face to face review process is simple and easy."}
            </div>
            <div className='pb-dev-demo-review-row'>
              <div className='pb-dev-demo-review-col'>
                <div className='pb-dev-demo-review-box'>
                  <div className='pb-dev-demo-review-content'>
                    <img
                      src='https://purebrand-electronics.myshopify.com/cdn/shop/files/Group_199_1024x1024.png?v=1718015313'
                      alt=''
                    />
                    <h3 className='pb-dev-demo-review-title'>
                      {formDataState.title_block_3 ||
                        "Recieve a discount code."}
                    </h3>
                    <p className='pb-dev-demo-review-info'>
                      {formDataState.info_block_3 ||
                        "As a token of our appreciation, we’ll send you a discount code for 10% off to your email. No need to purchase if you’re not convinced."}
                    </p>
                  </div>
                  <div className='pb-dev-review-number'>
                    <span className='pb-dev-review-num'>
                      {formDataState.index_block_3 || "3"}
                    </span>
                  </div>
                </div>
              </div>
              <div className='pb-dev-demo-review-col'>
                <div className='pb-dev-demo-review-box'>
                  <div className='pb-dev-demo-review-content'>
                    <img
                      src='https://purebrand-electronics.myshopify.com/cdn/shop/files/Group_199_1024x1024.png?v=1718015313'
                      alt=''
                    />
                    <h3 className='pb-dev-demo-review-title'>
                      {formDataState.title_block_2 ||
                        "Join a live demo with a real customer"}
                    </h3>
                    <p className='pb-dev-demo-review-info'>
                      {formDataState.info_block_2 ||
                        "Click on the link sent to your email and you’ll be connected to a video call with a real customer ready to demo their product."}
                    </p>
                  </div>
                  <div className='pb-dev-review-number'>
                    <span className='pb-dev-review-num'>
                      {formDataState.index_block_2 || "2"}
                    </span>
                  </div>
                </div>
              </div>
              <div className='pb-dev-demo-review-col'>
                <div className='pb-dev-demo-review-box'>
                  <div className='pb-dev-demo-review-content'>
                    <img
                      src='https://purebrand-electronics.myshopify.com/cdn/shop/files/Group_199_1024x1024.png?v=1718015313'
                      alt=''
                    />
                    <h3 className='pb-dev-demo-review-title'>
                      {formDataState.title_block_1 || "Schedule a review"}
                    </h3>
                    <p className='pb-dev-demo-review-info'>
                      {formDataState.info_block_1 ||
                        "Pick the product you’re interested in and schedule a demo at a time that works best for you."}
                    </p>
                  </div>
                  <div className='pb-dev-review-number'>
                    <span className='pb-dev-review-num'>
                      {formDataState.index_block_1 || "1"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Additional Sections */}
      {/* ================= "CUSTOMERS ARE LOVING REAL REVIEWS" + FORM ================= */}
      <div class='shopify-container'>
        <div class='testimonial-section'>
          <h2 class='heading pb-dev-first-form-title'>
            Customers are loving real reviews.
          </h2>

          <div class='pb-dev-quote-icon' style={{marginLeft: "3%",
      width: "fit-content"}}>
            <svg
              width='22'
              height='38'
              viewBox='0 0 22 38'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M12 0H22L15 38H0.5L12 0Z' fill='#D9D9D9' />
            </svg>
            <svg
              width='22'
              height='38'
              viewBox='0 0 22 38'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M12 0H22L15 38H0.5L12 0Z' fill='#D9D9D9' />
            </svg>
          </div>
          <br/>
          <div class='testimonial'>
            <p class='testimonial-text pb-dev-quote-text'>
              I loved my experience using Purebrand to see a Murphy Door. It
              helped me figure out which one would be right for my living room!
            </p>

            <div class='testimonial-author'>
              <div class='testimonial-image'>
                <img
                  src='https://cdn.shopify.com/s/files/1/0678/0813/3441/files/Rectangle_6305.png?v=1719409951'
                  alt='John Farias'
                />
              </div>
            </div>
          </div>
              <p class='author-name'>- John Farias</p>
        </div>

        <div class='form-section'>
          <h1>Let's get started</h1>

          <div class='form-group'>
            <label>
              Name <span class='required'>*</span>
            </label>
            <div class='input-icon'>
              <input type='text' placeholder='john doe' disabled />
              <span class='icon'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                  <path d='M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z' />
                </svg>
              </span>
            </div>
          </div>

          <div class='form-group'>
            <label>
              Number <span class='required'>*</span>
            </label>
            <div class='phone-input'>
              <div class='country-code'>
                <svg
                  class='us-flag-icon'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 640 480'
                >
                  <g fill-rule='evenodd'>
                    <g stroke-width='1pt'>
                      <path fill='#bd3d44' d='M0 0h640v480H0' />
                      <path
                        fill='#fff'
                        d='M0 55.3h640v55.4H0zm0 110.7h640v55.4H0zm0 110.7h640v55.4H0zm0 110.7h640v55.4H0zm0 110.7h640V480H0z'
                      />
                    </g>
                    <path fill='#192f5d' d='M0 0h364.8v258.5H0z' />
                    <path
                      fill='#fff'
                      d='M30.4 11l3.4 10.3h10.6l-8.6 6.3 3.3 10.3-8.7-6.4-8.6 6.3L25 27.6l-8.7-6.3h10.9zm60.8 0l3.3 10.3h10.8l-8.7 6.3 3.2 10.3-8.6-6.4-8.7 6.3 3.3-10.2-8.6-6.3h10.6zm60.8 0l3.3 10.3H166l-8.6 6.3 3.3 10.3-8.7-6.4-8.7 6.3 3.3-10.2-8.7-6.3h10.8zm60.8 0l3.3 10.3h10.8l-8.7 6.3 3.3 10.3-8.7-6.4-8.7 6.3 3.4-10.2-8.8-6.3h10.7zm60.8 0l3.3 10.3h10.7l-8.6 6.3 3.3 10.3-8.7-6.4-8.7 6.3 3.3-10.2-8.6-6.3h10.7zm60.8 0l3.3 10.3h10.8l-8.8 6.3 3.4 10.3-8.7-6.4-8.7 6.3 3.4-10.2-8.8-6.3h10.8zM60.8 36.4l3.3 10.2h10.8l-8.7 6.3 3.3 10.3-8.7-6.3-8.7 6.2 3.4-10.2-8.8-6.3h10.8zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.3-8.7-6.3-8.7 6.2 3.4-10.2-8.8-6.3h10.8zm60.8 0l3.3 10.2H196l-8.6 6.3 3.2 10.3-8.6-6.3-8.7 6.2 3.3-10.2-8.7-6.3h10.8zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.3-8.7-6.3-8.7 6.2 3.4-10.2-8.8-6.3h10.8zm60.8 0l3.3 10.2h10.7l-8.6 6.3 3.3 10.3-8.7-6.3-8.7 6.2 3.3-10.2-8.6-6.3h10.7zM30.4 61.7L33.7 72h10.7l-8.7 6.2 3.3 10.3-8.6-6.3-8.7 6.3L25 78.2l-8.6-6.3h10.7zm60.8 0L94.5 72h10.7l-8.6 6.2 3.2 10.3-8.6-6.3-8.7 6.3 3.3-10.3-8.6-6.3h10.7zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.2-8.7-6.2-8.7 6.2 3.4-10.2-8.8-6.3h10.8zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.2-8.7-6.2-8.7 6.2 3.4-10.2-8.8-6.3h10.8zm60.8 0l3.3 10.2h10.7l-8.6 6.3 3.3 10.2-8.7-6.2-8.7 6.2 3.3-10.2-8.6-6.3h10.7zm60.8 0l3.3 10.2h10.8l-8.8 6.3 3.4 10.2-8.7-6.2-8.7 6.2 3.4-10.2-8.8-6.3h10.8zM60.8 87.1l3.3 10.2h10.8l-8.7 6.3 3.3 10.2-8.7-6.3-8.7 6.3 3.4-10.3-8.8-6.2h10.8zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.2-8.7-6.3-8.7 6.3 3.4-10.3-8.8-6.2h10.8zm60.8 0l3.3 10.2H196l-8.6 6.3 3.2 10.2-8.6-6.3-8.7 6.3 3.3-10.3-8.7-6.2h10.8zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.2-8.7-6.3-8.7 6.3 3.4-10.3-8.8-6.2h10.8zm60.8 0l3.3 10.2h10.7l-8.6 6.3 3.3 10.2-8.7-6.3-8.7 6.3 3.3-10.3-8.6-6.2h10.7z'
                    />
                  </g>
                </svg>
                <span style={{color:'black'}}>+1</span>
              </div>
              <input type='tel' placeholder='201-555-0123' disabled />
            </div>
          </div>

          <div class='form-group'>
            <label>
              Email <span class='required'>*</span>
            </label>
            <div class='input-icon'>
              <input type='email' placeholder='john.doe@mail.com' disabled />
              <span class='icon'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                  <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z' />
                </svg>
              </span>
            </div>
          </div>

          <div class='form-group'>
            <label>
              Product Category <span class='required'>*</span>
            </label>
            <div class='select-wrapper'>
              <select className="form-select">
                <option selected disabled>
                  Select a category
                </option>

              </select>
            </div>
          </div>

          <div class='checkbox-group'>
            <input type='checkbox' id='consent' disabled />
            <label for='consent'>
              I agree to receive text messages at the phone number provided.
            </label>
          </div>

          <button class='btn btn-secondary' disabled>Meet Now</button>

          <div class='divider'>
            <span>Or</span>
          </div>

          <button class='btn btn-secondary' disabled>Schedule For Later</button>
        </div>
      </div>
      {/* Request Error & Custom Search Wrapper (if applicable) */}
      <div className='pb-dev-request-error-msg'></div>
      <div
        className='pb-dev-custom-search-wrapper'
        data-requestedFrom={props.pageHandle || ""}
        data-shopid={props.shopId || ""}
        data-shopEmail={props.shopEmail || ""}
        data-shopDomain={props.shopDomain || ""}
        data-shopName={props.shopName || ""}
      ></div>

      {/* Powered By Section */}
      <div className='pb-dev-powered-by-section'>
        <span className='pb-dev-powered-by-text'>Powered by</span>
        <img
          src='https://purebrand.io/static/media/Purebrand.a05df52f03301d4e9159.png'
          alt='Purebrand'
          className='pb-dev-powered-by-logo'
          width='150px'
          height='auto'
        />
      </div>

      {/* Demo Customers Section */}
      <div className='pb-dev-demo-customers'>
        <div className='pb-dev-demo-customer-container'>
          <div className='pb-dev-demo-customer-row'>
            <div className='pb-dev-demo-cust-col-right'>
              <div className='pb-dev-demo-cust-inner-right'>
                <div className='pb-dev-demo-cust-box'>
                  <div className='pb-dev-demo-cust-box-inners'>
                    <div className='pb-dev-demo-imgs'>
                      <img
                        src='//murphydoor.com/cdn/shop/files/Vector_8_1024x1024.png?v=1715937749'
                        className='pb-dev-demo-img-icon'
                        alt=''
                      />
                    </div>
                    <div className='pb-dev-demo-content'>
                      <h2 className='pb-dev-demo-titles'>Transparency</h2>
                      <div className='pb-dev-demo-info'>
                        Take a peak around the curtain with real demos from
                        people just like you.
                      </div>
                    </div>
                  </div>
                </div>

                <div className='pb-dev-demo-cust-box'>
                  <div className='pb-dev-demo-cust-box-inners'>
                    <div className='pb-dev-demo-imgs'>
                      <img
                        src='//murphydoor.com/cdn/shop/files/group-line_1024x1024.png?v=1715937749'
                        className='pb-dev-demo-img-icon'
                        alt=''
                      />
                    </div>
                    <div className='pb-dev-demo-content'>
                      <h2 className='pb-dev-demo-titles'>Face To Face</h2>
                      <div className='pb-dev-demo-info'>
                        What do products look like in their real environments.
                        Purebrand lets you see with no restrictions.
                      </div>
                    </div>
                  </div>
                </div>

                <div className='pb-dev-demo-cust-box'>
                  <div className='pb-dev-demo-cust-box-inners'>
                    <div className='pb-dev-demo-imgs'>
                      <img
                        src='//murphydoor.com/cdn/shop/files/Vector_9_1024x1024.png?v=1715937749'
                        className='pb-dev-demo-img-icon'
                        alt=''
                      />
                    </div>
                    <div className='pb-dev-demo-content'>
                      <h2 className='pb-dev-demo-titles'>Real Products</h2>
                      <div className='pb-dev-demo-info'>
                        See the products you’re looking for in action, and not
                        in the showroom.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopifyDetail;
