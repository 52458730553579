import AuthContext from 'context/AuthContext';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import ApiContext from 'context/ApiContext';
import { Spinner } from 'react-bootstrap';
import InfoText from "./InfoTextComponent";
import DotsFessAgreement from 'components/Agreements/DotsFessAgreement';
import * as Yup from 'yup';


const StoreUserProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      <DotsFessAgreement/>
      <div className="row">
        <div className="card cardc store_commission_card mt-3">
          <div className="card-body">
            <h5>Store Commission Info</h5>
            <hr />
            {isEditing === false ? (
              <CommissionView setIsEditing={setIsEditing} />
            ) : (
              <EditingView setIsEditing={setIsEditing} />
            )}
          </div>
        </div>
        <div className="card cardc store_commission_card mt-3">
          <div className="card-body">
            <StoreDetailView />
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreUserProfile;

const StoreDetailView = () => {
  const { getDataShopifyShop } = useContext(ApiContext);
  const { userProfile, updateShopifyStoreDetail, user } =
    useContext(AuthContext);
  const [shopData, setShopData] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await updateShopifyStoreDetail(values);
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const response = async () => {
      setLoading(true);
      try {
        let res = await getDataShopifyShop(userProfile?.store?.store_id);
        setShopData(res?.data?.data[0]);
        setLoading(false);
      } catch (error) {
        throw error;
      }
    };
    response();
  }, []);

  if (loading) {
    return (
      <div className="spinner-dots">
        <Spinner className="custom-blink" animation="grow" />
        <Spinner className="custom-blink" animation="grow" />
        <Spinner className="custom-blink" animation="grow" />
      </div>
    );
  }

  return (
    <>
      <h5>Shopify Shop Details</h5>
      <hr />
      {!!shopData && (
        <Formik
          initialValues={{
            delivery: shopData?.ship_day,
            name: shopData?.shop_name,
            logo: shopData?.logo,
            videoid: shopData?.videoid,
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="form-group ">
                <h6 className="m-2">Email Delivery days</h6>
                <Field
                  type="text"
                  name="delivery"
                  placeholder="Delivery Days"
                  className="form-control"
                  disabled={user.role === 5}
                />
                <h6 className="m-2">Shop Name</h6>
                <Field
                  type="text"
                  name="name"
                  placeholder="Shop Name"
                  className="form-control"
                  disabled={user.role === 5}
                />
                <button
                  type="submit"
                  className=" mt-3 add_email_button"
                  hidden={user.role === 5}
                >
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const CommissionView = (props) => {
  let { userProfile, user } = useContext(AuthContext);
  let { setIsEditing } = props;

  return (
    <>
      <div className="row">
        <div className="col col-sm-6 col-md-6 commission_setting_view_col">
          <h6 className="mb-0">
            <div className="commission_text">Demo Commission</div>
            <InfoText text="How much hosts will be paid for each meeting they are in." />
          </h6>
        </div>
        <div className="col col-sm-6 col-md-6 commission_info_percentage_text">
          {userProfile?.store?.demo_commission_type === "PERCENTAGE" ? (
            <>{userProfile?.store?.demo_commission}% </>
          ) : (
            <> ${userProfile?.store?.demo_commission}</>
          )}
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col col-sm-6 col-md-6 commission_setting_view_col">
          <h6 className="mb-0">
            <div className="commission_text">Sales Commission</div>
            <InfoText text="How much hosts will be paid on sales that their meetings generate." />
          </h6>
        </div>
        <div className="col col-sm-6 col-md-6 commission_info_percentage_text">
          {userProfile?.store?.demo_sales_commission_type === "PERCENTAGE" ? (
            <>{userProfile?.store?.demo_sales_commission}% </>
          ) : (
            <>${userProfile?.store?.demo_sales_commission} </>
          )}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col col-sm-6 col-md-6 commission_setting_view_col">
          <h6 className="mb-0">
            <div className="commission_text">No Show Commission</div>
            <InfoText text="How much hosts will be paid for each meeting they are in, but no guest shows up." />
          </h6>
        </div>
        <div className="col col-sm-6 col-md-6 commission_info_percentage_text">
          {userProfile?.store?.no_show_demo_commission_type === "PERCENTAGE" ? (
            <> {userProfile?.store?.no_show_demo_commission}%</>
          ) : (
            <>${userProfile?.store?.no_show_demo_commission}</>
          )}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col col-sm-6 col-md-6 commission_setting_view_col">
          <h6>
            <div className="commission_text">Discount Amount</div>
            <InfoText text="How much of a discount guests receive if they buy after their meeting." />
          </h6>
        </div>
        <div className="col col-sm-6 col-md-6 commission_info_percentage_text">
          {userProfile?.store?.discount_type === "PERCENTAGE" ? (
            <> {userProfile?.store?.discount_given}%</>
          ) : (
            <>${userProfile?.store?.discount_given}</>
          )}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col col-sm-6 col-md-8 commission_setting_view_col">
          <h6>
            <div className="commission_text">Sales Commission Time Eligibility</div>
            <InfoText text="Number of days after a meeting that the customer must buy for the Host to be eligible for Sales Commission payment." />
          </h6>
        </div>
        <div className="col col-sm-6 col-md-4 commission_info_percentage_text">
          <>{userProfile?.store?.sales_commission_order_threshold}</>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-12">
          <button
            className="add_email_button"
            onClick={() => setIsEditing(true)}
            hidden={user.role === 5}
          >
            Edit
          </button>
        </div>
      </div>
    </>
  );
};

const EditingView = (props) => {
  let { userProfile, updateStoreUserProfile } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  let { setIsEditing } = props;

  const validationSchema = Yup.object().shape({
    timeEligibility: Yup.number()
      .typeError('Must be a number')
      .integer('Must be an integer')
      .min(1, 'Value must be at least 1')
      .required('This field is required'),
  });

  const handleSubmit = (values) => {
    setSubmitting(true);

    const res = updateStoreUserProfile(values);
    res
      .then(function (response) {
        if (response.status === 200) {
          setSubmitting(false);
          setIsEditing(false);
        }
      })
      .catch(function (error) {
        setSubmitting(false);
        setError(true);
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          phone_number: userProfile?.phone_number,
          about_me: userProfile?.about_me,
          instagram_handle: userProfile?.user_social?.instagram_handle,
          twitter_handle: userProfile?.user_social?.twitter_handle,
          tiktok_handle: userProfile?.user_social?.tiktok_handle,
          facebook_handle: userProfile?.user_social?.facebook_handle,
          latitude: userProfile?.user_geo_location?.latitude,
          longitude: userProfile?.user_geo_location?.longitude,
          address: userProfile?.user_geo_location?.address,
          street_number: userProfile?.user_geo_location?.street_number,
          city: userProfile?.user_geo_location?.city,
          state: userProfile?.user_geo_location?.state,
          zip_code: userProfile?.user_geo_location?.zip_code,
          profile_picture_id: userProfile?.profile_picture?.id,
          store_email: userProfile?.user_shopify[0]?.store_email,
          demoCommission: userProfile?.store?.demo_commission,
          commissionType: userProfile?.store?.demo_commission_type,
          salesCommission: userProfile?.store?.demo_sales_commission,
          salesCommissionType: userProfile?.store?.demo_sales_commission_type,
          no_show_demo_commission_type:
            userProfile?.store?.no_show_demo_commission_type,
          no_show_demo_commission: userProfile?.store?.no_show_demo_commission,
          discountType: userProfile?.store?.discount_type,
          discountGiven: userProfile?.store?.discount_given,
          payment_frequency: userProfile?.store?.payment_frequency,
          timeEligibility: userProfile?.store?.sales_commission_order_threshold
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row ">
              <div className="mb-1 col-sm-12 col-md-12 commission_setting_view_col">
                <h6 className="mb-0">Demo Commission</h6>
              </div>
              <div className="col col-sm-8 col-md-6 text-secondary">
                <Field
                  placeholder="Demo commission (Eg. 12.99)"
                  type="text"
                  name="demoCommission"
                  className={`form-control form-control-sm`}
                />
              </div>
              <div
                className="col col-sm-4 col-md-2 text-secondary d-flex flex-column align-items-start"
                role="group"
              >
                <label className="mb-1 display-flex">
                  <Field type="radio" name="commissionType" value="AMOUNT" />
                  {" "}$
                </label>
              </div>
            </div>
            <hr />
            <div className="row ">
              <div className="mb-1 col-sm-12 col-md-12 commission_setting_view_col">
                <h6 className="mb-0">Sales Commission</h6>
              </div>
              <div className="col col-sm-8 col-md-6 text-secondary">
                <Field
                  placeholder="Sales commission (Eg. 12.99)"
                  type="text"
                  name="salesCommission"
                  className={`form-control form-control-sm`}
                />
              </div>
              <div
                className="col col-sm-4 col-md-2 text-secondary d-flex flex-column align-items-start"
                role="group"
              >
                <label className="mb-1 display-flex">
                  <Field type="radio" name="salesCommissionType" value="AMOUNT" />
                  $
                </label>
                <label className="mb-1 display-flex">
                  <Field type="radio" name="salesCommissionType" value="PERCENTAGE" />
                  %
                </label>
              </div>
            </div>
            <hr />
            <div className="row ">
              <div className="mb-1 col-sm-12 col-md-12 commission_setting_view_col">
                <h6 className="mb-0">No Show Commission</h6>
              </div>
              <div className="col col-sm-8 col-md-6 text-secondary">
                <Field
                  placeholder="No show commission (Eg. 12.99)"
                  type="text"
                  name="no_show_demo_commission"
                  className={`form-control form-control-sm`}
                />
              </div>
              <div
                className="col col-sm-4 col-md-2 text-secondary d-flex flex-column align-items-start"
                role="group"
              >
                <label className="mb-1 display-flex">
                  <Field type="radio" name="no_show_demo_commission_type" value="AMOUNT" />
                  $
                </label>
              </div>
            </div>
            <hr />
            <div className="row ">
              <div className="col-sm-12 col-md-12 mb-1 commission_setting_view_col">
                <h6 className="mb-0">Discount</h6>
              </div>
              <div className="col col-sm-8 col-md-6 text-secondary">
                <Field
                  placeholder="Discount (Eg. 12.99)"
                  type="text"
                  name="discountGiven"
                  className={`form-control form-control-sm`}
                />
              </div>
              <div
                className="col col-sm-4 col-md-2 text-secondary d-flex flex-column align-items-start"
                role="group"
              >
                <label className="mb-1 display-flex">
                  <Field type="radio" name="discountType" value="AMOUNT" />
                  $
                </label>
                <label className='mb-1 display-flex'>
                  <Field type="radio" name="discountType" value="PERCENTAGE" />
                  %
                </label>
              </div>
            </div>
            <hr />
            <div className="row ">
              <div className="col-sm-12 col-md-12 mb-1 commission_setting_view_col">
                <h6 className="mb-0">Sales Commission Time Eligibility</h6>
              </div>
              <div className="col col-sm-8 col-md-6 text-secondary">
                <Field
                  placeholder="Days (Eg. 30)"
                  type="number"
                  name="timeEligibility"
                  min="1"
                  className={`form-control form-control-sm`}
                />
                {errors?.timeEligibility && touched?.timeEligibility && (
                  <div className="invalid-feedback" style={{display: "block"}}>{errors?.timeEligibility}</div>
                )}
              </div>
            </div>
            <hr />
            {error ? (
              <>
                <div>
                  <p>Some error occured, check details</p>
                </div>
                <hr />
              </>
            ) : (
              <></>
            )}

            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-9 text-secondary">
                <button
                  className="add_email_button"
                  type="submit"
                  disabled={submitting}
                >
                  Save Changes
                </button>
                <button
                  className="add_email_button"
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
