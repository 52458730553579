import AuthContext from "context/AuthContext";
import ProfileContext from "context/ProfileContext";
import React, { useContext, useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AgreementTC from "../AgreementTC";
import BasicInfo from "../ProfileCompletion/BasicInfo";
import ProfileInfo from "../ProfileCompletion/ProfileInfo";
import ShopifyDetail from "./ShopifyDetail";
import StoreDetail from "./StoreDetail";
import PurebrandLoader from "components/Loader/PurebrandLoader";

const StoreProfileForm = () => {
  let { user } = useContext(AuthContext);
  let { fetchPreviousStepData, formDataState,setFormDataState, updateHalfProfile, putToS3, setupStoreProfile } = useContext(ProfileContext);
  const navigate = useNavigate();
  const location = useLocation();

  // If coming from settings, we get resumeStep from location state.
  const savedData = JSON.parse(localStorage.getItem("userProfile")) || {};
  const initialStep = savedData.half
    ? 3
    : location.state && location.state.resumeStep !== undefined
    ? location.state.resumeStep
    : 0;
  
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [isLoading, setIsLoading] = useState(initialStep === 3); // Set loading state initially

  const makeRequest = (formData) => {
    const response = setupStoreProfile(formData);
    response
      .then(function (response) {
        if (response.status === 200) {
          navigate("/user/welcome");
        }
      })
      .catch(function (err) {
        console.error("Error Occured");
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetchPreviousStepData(user.uid);
        if (response.data.data && response.data.halfProfile) {
          setFormDataState(prev => ({ ...prev, ...response.data.data }));
          localStorage.setItem("userProfile", JSON.stringify({...response.data.data, initialStoreName: response.data.store.store_name}));
          setCurrentStep(3);
        } else {
        }
      } catch (error) {
        console.error("Error fetching previous step data:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (user?.uid) {
      fetchData();
    }
  }, [user?.uid]);

  const handleNextStep = async (newData, final = false, half = false) => {
    setFormDataState((prev) => ({ ...prev, ...newData }));

    if (final) {
      // Merge newData and savedData using ternary operators to set fallbacks.
      const completeStoreDataObj = {
        phone_number: newData.phone_number || savedData.phone_number || "",
        about_me: newData.about_me || savedData.about_me || "",
        instagram_handle:
          newData.instagram_handle !== undefined
            ? newData.instagram_handle
            : savedData.user_social
            ? savedData.user_social.instagram_handle
            : "",
        twitter_handle:
          newData.twitter_handle !== undefined
            ? newData.twitter_handle
            : savedData.user_social
            ? savedData.user_social.twitter_handle
            : "",
        tiktok_handle:
          newData.tiktok_handle !== undefined
            ? newData.tiktok_handle
            : savedData.user_social
            ? savedData.user_social.tiktok_handle
            : "",
        facebook_handle:
          newData.facebook_handle !== undefined
            ? newData.facebook_handle
            : savedData.user_social
            ? savedData.user_social.facebook_handle
            : "",
        latitude:
          newData.latitude ||
          (savedData.user_geo_location &&
            savedData.user_geo_location.latitude) ||
          "",
        longitude:
          newData.longitude ||
          (savedData.user_geo_location &&
            savedData.user_geo_location.longitude) ||
          "",
        address:
          newData.address ||
          (savedData.user_geo_location &&
            savedData.user_geo_location.address) ||
          "",
        street_number:
          newData.street_number ||
          (savedData.user_geo_location &&
            savedData.user_geo_location.street_number) ||
          "",
        city:
          newData.city ||
          (savedData.user_geo_location && savedData.user_geo_location.city) ||
          "",
        state:
          newData.state ||
          (savedData.user_geo_location && savedData.user_geo_location.state) ||
          "",
        zip_code:
          newData.zip_code ||
          (savedData.user_geo_location &&
            savedData.user_geo_location.zip_code) ||
          "",
        profile_picture_id:
          newData.profile_picture_id ||
          (savedData.profile_picture && savedData.profile_picture.id) ||
          "",
        imageFile: newData.imageFile || [],
        files: newData.files || "",
        videofile: newData.videofile || "",
        tag: newData.tag || "",
        type: newData.type || "",
        extension: newData.extension || "",
        store_email: newData.store_email || "",
        id: newData.id || "",
        social_selected:
          newData.social_selected !== undefined
            ? newData.social_selected
            : true,
        // store: {
          demoCommission:
            newData.demoCommission || savedData.store?.demo_commission || 0,
          commissionType: newData.commissionType || savedData?.store?.demo_commission_type || "AMOUNT",
          salesCommission:
            newData.salesCommission ||
            savedData.store?.demo_sales_commission ||
            0,
          salesCommissionType: newData.salesCommissionType ||savedData?.store?.demo_sales_commission_type || "AMOUNT",
          discountType: newData.discountType|| savedData?.store?.discount_type || "AMOUNT",
          discountGiven:
            newData.discountGiven || savedData.store?.discount_given || 0,
          paymentFrequency:
            newData.paymentFrequency || savedData.store?.payment_frequency || 1,
          no_show_demo_commission_type:
            newData.no_show_demo_commission_type|| savedData?.store?.no_show_demo_commission_type || "AMOUNT",
          no_show_demo_commission:
            newData.no_show_demo_commission ||
            savedData.store?.no_show_demo_commission ||
            0,
        // },
        shopName: newData.shopName || "",
        shipDate: newData.shipDate || "",
        initialUpdate: true,
      };
        makeRequest(completeStoreDataObj);  // Ensure `makeRequest` completes before exiting
        return;
    }

    if (half) {
      setIsLoading(true);
        // Prepare image data for upload
        const imageData = {
            tag: "profile_photo",
            type: "image",
            extension: "jpg",
            file: newData.imageFile,
        };

        try {
            // Await `putToS3` so `updateHalfProfile` is only called after success
            const res = await putToS3(imageData);
            if (res.status === 200) {
                newData.profile_picture_id = res.data.data.id;
                const storeDataObj = {
                  demo_commission:
                    newData.demoCommission ||
                    savedData.store?.demo_commission ||
                    0,
                    demo_commission_type: newData.commissionType|| savedData.store?.demo_commission_type || "AMOUNT",
                  demo_sales_commission:
                    newData.salesCommission ||
                    savedData.store?.demo_sales_commission ||
                    0,
                  demo_sales_commission_type: newData.salesCommissionType|| savedData?.store?.demo_sales_commission_type || "AMOUNT",
                  discount_type: newData.discountType|| savedData?.store?.discount_type || "AMOUNT",
                  discount_given:
                    newData.discountGiven ||
                    savedData.store?.discount_given ||
                    0,
                  payment_frequency:
                    newData.paymentFrequency ||
                    savedData.store?.payment_frequency ||
                    1,
                  no_show_demo_commission_type:
                    newData.no_show_demo_commission_type || "AMOUNT",
                  no_show_demo_commission:
                    newData.no_show_demo_commission ||
                    savedData.store?.no_show_demo_commission ||
                    0,
                };
                const formattedData = {
                  ...newData,
                  store:storeDataObj
              };
                // Now update the half profile
                const profileRes = await updateHalfProfile(formattedData);
                if (profileRes) {
                  if (!profileRes?.data?.data?.store?.is_compliance_added) {
                    navigate("/user/settings?page=adminDots", {state:{completeProfileFlow:true}});
                  } 
                  // check if you need this else condition in future
                  // else {
                  //   alert("Something went wrong during profile update");
                  // }
                }
            } else {
                console.log("Failed to upload image:", res);
                alert("Image upload failed. Please try again.");
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error during image upload:", error);
            setCurrentStep((prev) => prev - 1);
            setIsLoading(false);
            alert("An error occurred while uploading the image.");
        }
    }

    setCurrentStep((prev) => prev + 1);
};


  const steps = [
    <BasicInfo next={handleNextStep} data={formDataState} />,
    <ProfileInfo next={handleNextStep} data={formDataState} />,
    <StoreDetail next={handleNextStep} data={formDataState} />,
    <ShopifyDetail next={handleNextStep} data={formDataState} />,
    <AgreementTC next={handleNextStep} data={formDataState} />,
  ];

  return (
    <div>
      <ProgressBar
        className="step-progress-bar"
        variant="info"
        now={currentStep + 1}
        min={0}
        max={5}
        label={`${currentStep + 1}/5`}
      />
      {isLoading ? <PurebrandLoader />: steps[currentStep]}
      
    </div>
  );
};

export default StoreProfileForm;
