import React, { useEffect, useState } from "react";
import AuthContext from "context/AuthContext";
import ProfileContext from "context/ProfileContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext } from "react";
import ApiContext from "context/ApiContext";
import { UploadImage } from "components/Forms/Multistep/ProfileCompletion/ProfileInfo";
import { FeatherImg, IphoneLayout } from "assets/images";
import ReactPlayer from "react-player";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import "./shopifyDetails.css";
import "../../../../components/Forms/Multistep/ProfileStore/newUi.css"
import { Button } from "antd";
import Dashelement from "components/Dashboard/Dashelement";


const ShopifyDetails = () => {
  const {
    getDataShopifyShop,
    getShopifyLogo,
    updateShopifyLogo,
    getShopifyVideo,
    updateShopifyVideo,
  } = useContext(ApiContext);
  const {
    user,
    userProfile,
    updateShopifyStoreDetail,
    uploader,
    storeVideoUploader,
  } = useContext(AuthContext);
  const { formDataState, setFormDataState } = useContext(ProfileContext);
  const [shopData, setShopData] = useState();
  const [videoData, setVideoData] = useState();
  const [avatar, setAvatar] = useState();
  const [videoFile, setVideoFile] = useState();
  const [loading, setLoading] = useState(false);
  const [logoUpdateLoading, setlogoUpdateLoading] = useState(false);
  const [videoUpdateLoading, setvideoUpdateLoading] = useState(false);
  const [videoFileUpload, setVideoFileUpload] = useState();
  const [isError, setIsError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [scale, setScale] = useState(1);
  /**
   * This function is triggered when a user uploads a logo
   *
   * @param {object} values Values for file data to upload
   * @return {void}
   */

  const handleLogoUpload = (values) => {
    setlogoUpdateLoading(true);
    uploader(values)
      .then(() => {
        updateShopifyLogo(values).then((res) => {
          updateLogoId(res.data.data.id);
          setlogoUpdateLoading(false);
        });
      })
      .catch((error) => {
        setlogoUpdateLoading(false);
        throw error;
      });
  };

  /**
   * This function is a for handling video upload
   *
   * @param {void}
   * @return {void}
   */

  const handleVideoUpload = async () => {
    setvideoUpdateLoading(true);
    if (videoData) {
      let payload = {
        storeid: shopData?.shop_id,
        logo: shopData?.logo,
        nameShop: shopData?.shop_name,
        days: shopData?.ship_day,
        video: videoData,
      };
      try {
        const res = await storeVideoUploader(payload);
        updateVideoId(res.data.data.id);
        if (res) {
          await updateShopifyVideo(videoData);
        }
        setvideoUpdateLoading(false);
        setVideoData(null);
      } catch (error) {
        console.error("Error uploading video:", error);
        setvideoUpdateLoading(false);
      }
    } else {
      setvideoUpdateLoading(false);
    }
  };

  /**
   * This function is for updating the logo id in shop data state
   *
   * @param {void}
   * @return {void}
   */
  const updateLogoId = (newLogo) => {
    setShopData((prevState) => ({
      ...prevState,
      logo: newLogo,
    }));
  };

  /**
   * This function is for updating the video id in shop data state
   *
   * @param {void}
   * @return {void}
   */
  const updateVideoId = (newVideoId) => {
    setShopData((prevState) => ({
      ...prevState,
      videoid: newVideoId,
    }));
  };

  /**
   * This function is triggered when a user uploads a video and restricts the user if the file size exceeds the limit.
   *
   * @param {object} values Values for file data to upload
   * @return {void}
   */

  const handleVideoChange = (event) => {
    setvideoUpdateLoading(true);
    try {
      const file = event.target.files[0];
      if (file) {
        // Check file size
        if (file.size > 100 * 1024 * 1024) {
          // 100 MB limit
          setIsError(true);
          event.target.value = null;
          setvideoUpdateLoading(false);
          return;
        }

        setVideoData(file);
        const upload_video = URL.createObjectURL(file);
        setVideoFileUpload(upload_video);
        setIsError(false);
      }
    } catch (err) {
      console.error("Error handling video change:", err);
    } finally {
      setvideoUpdateLoading(false);
    }
  };

  /**
   * This function is for handling the logo submit
   *
   * @param {object} LogoObject that handle logo data
   * @return {void}
   */

  const handleSubmit = async (values) => {
    
    try {
      // If we have video data, upload the video
      if (videoData) {
        await handleVideoUpload();
      }
      
      // If we have logo files, upload the logo
      if (values.files && values.files.length > 0) {
        await handleLogoUpload(values);
      }
      
      // Update store details
      const updateData = {
        delivery: values.delivery || shopData?.ship_day,
        name: values.name || shopData?.shop_name,
        logo: shopData?.logo,
        videoid: shopData?.videoid
      };
      
      await updateShopifyStoreDetail(updateData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  /**
   * Create initial form data from props and local storage
   */
  const createInitialFormData = (propsData) => {
    // Start with defaults
    let formData = {
      delivery: "",
      name: "",
      files: null,
      videofile: null,
    };

    // Merge any existing props data
    formData = { ...formData };

    // If shop data exists, use it
    if (shopData) {
      formData.delivery = shopData.ship_day;
      formData.name = shopData.shop_name;
      formData.logo = shopData.logo;
      formData.videoid = shopData.videoid;
    }

    // If localStorage contains a userProfile, override values
    const userProfileStr = localStorage.getItem("userProfile");
    if (userProfileStr) {
      const parsedProfile = JSON.parse(userProfileStr);
      if (parsedProfile.initialStoreName) {
        formData.name = parsedProfile.initialStoreName;
      }
    }

    return formData;
  };

  /**
   * Process the image file
   */
  const getImage = (event) => {
    if (!event || !event[0]) return;
    
    let reader = new FileReader();
    reader.readAsDataURL(event[0]);

    reader.onload = (e) => {
      setAvatar(e.target.result);
    };
  };

  /**
   * Get shop data on component mount
   */
  useEffect(() => {
    const fetchShopData = async () => {
      setLoading(true);
      try {
        let res = await getDataShopifyShop(userProfile?.store?.store_id);
        setShopData(res?.data?.data[0]);
        
        if (res) {
          const logoId = res?.data?.data[0]?.logo;
          const videoId = res?.data?.data[0]?.videoid;

          // No need to set this default image. we are already showing the file requirements
          // if (!logoId || logoId === "undefined" || logoId === "null") {
          //   setAvatar(FeatherImg);
          // } else {
            try {
              const logo = await getShopifyLogo(logoId);
              setAvatar(logo?.data?.file);
            } catch (err) {
              console.error("Error loading logo:", err);
              // setAvatar(FeatherImg);
            }
          // }

          if (!videoId || videoId === "undefined" || videoId === "null") {
            setVideoFile(undefined);
          } else {
            try {
              const video = await getShopifyVideo(videoId);
              setVideoFile(video?.data?.file);
            } catch (err) {
              console.error("Error loading video:", err);
            }
          }
        }
      } catch (err) {
        console.error("Error fetching shop data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchShopData();
  }, []);

  /**
   * Update scale and device settings on resize
   */
  useEffect(() => {
    const updateScaleAndDevice = () => {
      const windowWidth = window.innerWidth;
      let newScale = 1;
      if (windowWidth < 768) {
        setIsMobile(true);
        newScale = 1.2;
      } else if (windowWidth < 1200) {
        setIsMobile(false);
        newScale = 1.5;
      } else {
        setIsMobile(false);
        newScale = 2;
      }
      setScale(newScale);
    };

    updateScaleAndDevice();
    window.addEventListener("resize", updateScaleAndDevice);
    return () => window.removeEventListener("resize", updateScaleAndDevice);
  }, []);

  // Define the small video style based on screen width
  const smallVideoStyle = isMobile
    ? {
        position: "absolute",
        bottom: "17%",
        left: "40%",
        transform: `translateX(-50%) rotate(6.7deg) scale(${scale})`,
        width: "80px",
        height: "60px",
      }
    : window.innerWidth < 1200
    ? {
        position: "absolute",
        bottom: "20%",
        left: "38%",
        transform: `translateX(-50%) rotate(6.7deg) scale(${scale})`,
        width: "80px",
        height: "60px",
      }
    : {
        position: "absolute",
        bottom: "20%",
        left: "33%",
        transform: `rotate(6.7deg) scale(${scale})`,
        width: "80px",
        height: "60px",
      };

  if (loading) {
    return <PurebrandLoader />;
  }

  const playVideo = videoFileUpload ? videoFileUpload : videoFile;
  const initialFormData = createInitialFormData(formDataState);

  return (
    <>
      {loading && (
        <PurebrandLoader
          textMessage="Uploading"
          colorCode="rgba(248, 248, 248, 0.7)"
        />
      )}
      
      {/* =================== FORM SECTION =================== */}
      <Formik
        initialValues={initialFormData}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, dirty }) => {
           // Check if either video or logo has changed
    const videoChanged = videoData 
    // videoData !== null || videoFileUpload !== undefined;
    const logoChanged = avatar || (values.files && values.files.length > 0);
    
    // Combine Formik's dirty state with file changes to determine if any changes exist
    const hasChanges = dirty || videoChanged || logoChanged;
    return (
          <Form>
            <Dashelement className="features basic-info-wrapper">
              <div className="container">
                <div className="row">
                  {/* Left Column: Requirements */}
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 card cardc store_commission_card mt-3">
                    <div className="card-body">
                      <h5>Shopify Shop Details</h5>
                      <div className="requirements-section mb-3">
                        <h6 className="requirements-header">
                          Requirements for Logo and Video
                        </h6>
                        <ul className="requirements-list">
                          <li>
                            <span className="label">Logo file formats:</span>{" "}
                            <span>.jpg, .png, .gif</span>
                          </li>
                          <li>
                            <span className="label">Video file formats:</span>{" "}
                            <span>.mp4, .mov, .avi</span>
                          </li>
                          <li>
                            <span className="label">Maximum file size:</span>{" "}
                            <span>10MB for logo, 100MB for video</span>
                          </li>
                          <li>
                            <span className="label">File name length:</span>{" "}
                            <span>less than 100 characters</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                  {/* Middle Column: Upload store logo */}
                  <div className="col col-sm-12 col-md-4 col-lg-4">
                    <UploadImage
                      setFieldValue={setFieldValue}
                      value="files"
                      class="uploader-parts"
                      title="Upload Your Store Logo"
                      maxSize={10000000}
                      name="logo"
                      disabled={user.role === 5}
                      body={
                        !avatar ? (
                          <div className="mb-3">
                            <ul>
                              <li>Accepted file formats: .jpg, .png, .gif</li>
                              <li>Maximum file size: 10MB</li>
                              <li>
                                File name length: less than 100 characters
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <div>
                            <img
                              src={avatar}
                              className="img img_small_viewfinder"
                              alt="Store Logo"
                            />
                          </div>
                        )
                      }
                      footer="Click here or drag image here."
                      accept={{ "image/*": [] }}
                    />
                    {values.files && getImage(values.files)}
                  </div>
                  
                  {/* Right Column: Upload store video */}
                  <div className="col col-sm-12 col-md-4 col-lg-4">
                    <div className="uploader-parts" style={{ height: "auto" }}>
                      <h5 className="upload-text">Upload and Preview Video</h5>
                      <div className="video-uploader-container">
                        <input
                          type="file"
                          accept="video/*"
                          onChange={(event) => {
                            setFieldValue(
                              "videofile",
                              event.currentTarget.files[0]
                            );
                            handleVideoChange(event);
                          }}
                          className="btn btn-upload-video w-100 p-2"
                          disabled={user.role === 5}
                        />
                        
                        {isError && (
                          <p className="error-message">
                            File size exceeds 100MB limit. Please choose a smaller file.
                          </p>
                        )}
                        
                        {playVideo ? (
                          <div>
                            <div className="upload-text">
                              <ReactPlayer
                                url={playVideo}
                                controls
                                playing
                                loop
                                muted
                                width="100%"
                                height="auto"
                                style={{ objectFit: "cover" }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="uploader-part-video">
                            <div className="upload-text">
                              Click the button above to select a video.
                              <br />
                              Requirements:
                              <ul>
                                <li>.mp4, .mov, .avi file type</li>
                                <li>less than 100MB</li>
                                <li>file name is less than 100 characters</li>
                                <li>
                                  make sure the video is in portrait mode and
                                  should fit this preview screen.
                                </li>
                              </ul>
                              <img
                                src={FeatherImg}
                                alt="Feather"
                                width="100px"
                                className="upload-text"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dashelement>
            
            {/* Submit Button */}
            <div className="text-center mt-3">
              <Button
                type="submit"
                onClick={() => handleSubmit(values)}
                className="update-logo-button"
                loading={logoUpdateLoading || videoUpdateLoading}
                disabled={user.role === 5 || !hasChanges}
              >
                Update Details
              </Button>
            </div>
          </Form>
    )}
        }
      </Formik>
      
      {/* =================== PREVIEW SECTION =================== */}
      <div
        className="pb-dev-demo-img-text"
        style={{ background: "#fff", padding: "50px 0", marginTop: "40px" }}
      >
        <div className="pb-dev-demo-container">
          <div className="pb-dev-demo-img-row">
            {/* Left Column: Title & Subheading */}
            <div className="pb-dev-demo-left-col">
              <div className="pb-dev-demo-left-inner">
                <h2 className="pb-dev-demo-title">Your questions, answered.</h2>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="pb-dev-svg-for-round"
                  viewBox="0 0 507 33"
                  fill="none"
                >
                  <path
                    d="M4 4C76.538 22.8656 277.891 49.2774 503 4"
                    stroke="#23265F"
                    strokeWidth="8"
                    strokeLinecap="round"
                  />
                </svg>
                <p className="pb-dev-demo-contentss">
                  Murphy Door has partnered with Purebrand to bring you demos
                  with real customers and see our products in action!
                </p>
              </div>
            </div>

            {/* Right Column: The angled phone container */}
            <div className="pb-dev-demo-right-col">
              <div className="pb-dev-demo-right-inner pb-dev-container-image">
                <div className="pb-dev-video-main-box">
                  <div className="pb-dev-video-inner-box">
                    {playVideo ? (
                      <ReactPlayer
                        url={playVideo}
                        playing
                        loop
                        muted
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          background: "#fff",
                        }}
                      />
                    )}
                  </div>

                  <div className="small-video-preview" style={smallVideoStyle}>
                    <ReactPlayer
                      url="https://cdn.shopify.com/videos/c/o/v/5d524bf539fa466589613c635bcb91d6.mp4"
                      playing
                      muted
                      loop
                      width="100%"
                      height="100%"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== REVIEWS SECTION =================== */}
      <div
        className='pb-dev-demo-review'
        style={{ background: "#fff", padding: "50px 0" }}
      >
        <div
          className='pb-dev-demo-review-container'
          style={{ maxWidth: "80%" }}
        >
          <div className='pb-dev-demo-desk-img'>
            {/* Use a demo image from state or a default */}
            <img
              src={
                formDataState.demo_image ||
                "https://via.placeholder.com/600x400"
              }
              alt='Review'
            />
          </div>
          <div className='pb-dev-demo-mobile-review'>
            <div className='pb-dev-demo-mobil-title'>
              {formDataState.demo_title || "How do the reviews work?"}
            </div>
            <div className='pb-dev-demo-mobil-infos'>
              {formDataState.demo_info ||
                "Our face to face review process is simple and easy."}
            </div>
            <div className='pb-dev-demo-review-row'>
              <div className='pb-dev-demo-review-col'>
                <div className='pb-dev-demo-review-box'>
                  <div className='pb-dev-demo-review-content'>
                    <img
                      src='https://purebrand-electronics.myshopify.com/cdn/shop/files/Group_199_1024x1024.png?v=1718015313'
                      alt=''
                    />
                    <h3 className='pb-dev-demo-review-title'>
                      {formDataState.title_block_3 ||
                        "Recieve a discount code."}
                    </h3>
                    <p className='pb-dev-demo-review-info'>
                      {formDataState.info_block_3 ||
                        "As a token of our appreciation, we’ll send you a discount code for 10% off to your email. No need to purchase if you’re not convinced."}
                    </p>
                  </div>
                  <div className='pb-dev-review-number'>
                    <span className='pb-dev-review-num'>
                      {formDataState.index_block_3 || "3"}
                    </span>
                  </div>
                </div>
              </div>
              <div className='pb-dev-demo-review-col'>
                <div className='pb-dev-demo-review-box'>
                  <div className='pb-dev-demo-review-content'>
                    <img
                      src='https://purebrand-electronics.myshopify.com/cdn/shop/files/Group_199_1024x1024.png?v=1718015313'
                      alt=''
                    />
                    <h3 className='pb-dev-demo-review-title'>
                      {formDataState.title_block_2 ||
                        "Join a live demo with a real customer"}
                    </h3>
                    <p className='pb-dev-demo-review-info'>
                      {formDataState.info_block_2 ||
                        "Click on the link sent to your email and you’ll be connected to a video call with a real customer ready to demo their product."}
                    </p>
                  </div>
                  <div className='pb-dev-review-number'>
                    <span className='pb-dev-review-num'>
                      {formDataState.index_block_2 || "2"}
                    </span>
                  </div>
                </div>
              </div>
              <div className='pb-dev-demo-review-col'>
                <div className='pb-dev-demo-review-box'>
                  <div className='pb-dev-demo-review-content'>
                    <img
                      src='https://purebrand-electronics.myshopify.com/cdn/shop/files/Group_199_1024x1024.png?v=1718015313'
                      alt=''
                    />
                    <h3 className='pb-dev-demo-review-title'>
                      {formDataState.title_block_1 || "Schedule a review"}
                    </h3>
                    <p className='pb-dev-demo-review-info'>
                      {formDataState.info_block_1 ||
                        "Pick the product you’re interested in and schedule a demo at a time that works best for you."}
                    </p>
                  </div>
                  <div className='pb-dev-review-number'>
                    <span className='pb-dev-review-num'>
                      {formDataState.index_block_1 || "1"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Additional Sections */}
      {/* ================= "CUSTOMERS ARE LOVING REAL REVIEWS" + FORM ================= */}
      <div class='shopify-container'>
        <div class='testimonial-section'>
          <h2 class='heading pb-dev-first-form-title'>
            Customers are loving real reviews.
          </h2>

          <div
            class='pb-dev-quote-icon'
            style={{ marginLeft: "3%", width: "fit-content" }}
          >
            <svg
              width='22'
              height='38'
              viewBox='0 0 22 38'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M12 0H22L15 38H0.5L12 0Z' fill='#D9D9D9' />
            </svg>
            <svg
              width='22'
              height='38'
              viewBox='0 0 22 38'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M12 0H22L15 38H0.5L12 0Z' fill='#D9D9D9' />
            </svg>
          </div>
          <br />
          <div class='testimonial'>
            <p class='testimonial-text pb-dev-quote-text'>
              I loved my experience using Purebrand to see a Murphy Door. It
              helped me figure out which one would be right for my living room!
            </p>

            <div class='testimonial-author'>
              <div class='testimonial-image'>
                <img
                  src='https://cdn.shopify.com/s/files/1/0678/0813/3441/files/Rectangle_6305.png?v=1719409951'
                  alt='John Farias'
                />
              </div>
            </div>
          </div>
          <p class='author-name'>- John Farias</p>
        </div>

        <div class='form-section'>
          <h1>Let's get started</h1>

          <div class='form-group'>
            <label>
              Name <span class='required'>*</span>
            </label>
            <div class='input-icon'>
              <input type='text' placeholder='john doe' disabled />
              <span class='icon'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                  <path d='M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z' />
                </svg>
              </span>
            </div>
          </div>

          <div class='form-group'>
            <label>
              Number <span class='required'>*</span>
            </label>
            <div class='phone-input'>
              <div class='country-code'>
                <svg
                  class='us-flag-icon'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 640 480'
                >
                  <g fill-rule='evenodd'>
                    <g stroke-width='1pt'>
                      <path fill='#bd3d44' d='M0 0h640v480H0' />
                      <path
                        fill='#fff'
                        d='M0 55.3h640v55.4H0zm0 110.7h640v55.4H0zm0 110.7h640v55.4H0zm0 110.7h640v55.4H0zm0 110.7h640V480H0z'
                      />
                    </g>
                    <path fill='#192f5d' d='M0 0h364.8v258.5H0z' />
                    <path
                      fill='#fff'
                      d='M30.4 11l3.4 10.3h10.6l-8.6 6.3 3.3 10.3-8.7-6.4-8.6 6.3L25 27.6l-8.7-6.3h10.9zm60.8 0l3.3 10.3h10.8l-8.7 6.3 3.2 10.3-8.6-6.4-8.7 6.3 3.3-10.2-8.6-6.3h10.6zm60.8 0l3.3 10.3H166l-8.6 6.3 3.3 10.3-8.7-6.4-8.7 6.3 3.3-10.2-8.7-6.3h10.8zm60.8 0l3.3 10.3h10.8l-8.7 6.3 3.3 10.3-8.7-6.4-8.7 6.3 3.4-10.2-8.8-6.3h10.7zm60.8 0l3.3 10.3h10.7l-8.6 6.3 3.3 10.3-8.7-6.4-8.7 6.3 3.3-10.2-8.6-6.3h10.7zm60.8 0l3.3 10.3h10.8l-8.8 6.3 3.4 10.3-8.7-6.4-8.7 6.3 3.4-10.2-8.8-6.3h10.8zM60.8 36.4l3.3 10.2h10.8l-8.7 6.3 3.3 10.3-8.7-6.3-8.7 6.2 3.4-10.2-8.8-6.3h10.8zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.3-8.7-6.3-8.7 6.2 3.4-10.2-8.8-6.3h10.8zm60.8 0l3.3 10.2H196l-8.6 6.3 3.2 10.3-8.6-6.3-8.7 6.2 3.3-10.2-8.7-6.3h10.8zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.3-8.7-6.3-8.7 6.2 3.4-10.2-8.8-6.3h10.8zm60.8 0l3.3 10.2h10.7l-8.6 6.3 3.3 10.3-8.7-6.3-8.7 6.2 3.3-10.2-8.6-6.3h10.7zM30.4 61.7L33.7 72h10.7l-8.7 6.2 3.3 10.3-8.6-6.3-8.7 6.3L25 78.2l-8.6-6.3h10.7zm60.8 0L94.5 72h10.7l-8.6 6.2 3.2 10.3-8.6-6.3-8.7 6.3 3.3-10.3-8.6-6.3h10.7zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.2-8.7-6.2-8.7 6.2 3.4-10.2-8.8-6.3h10.8zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.2-8.7-6.2-8.7 6.2 3.4-10.2-8.8-6.3h10.8zm60.8 0l3.3 10.2h10.7l-8.6 6.3 3.3 10.2-8.7-6.2-8.7 6.2 3.3-10.2-8.6-6.3h10.7zm60.8 0l3.3 10.2h10.8l-8.8 6.3 3.4 10.2-8.7-6.2-8.7 6.2 3.4-10.2-8.8-6.3h10.8zM60.8 87.1l3.3 10.2h10.8l-8.7 6.3 3.3 10.2-8.7-6.3-8.7 6.3 3.4-10.3-8.8-6.2h10.8zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.2-8.7-6.3-8.7 6.3 3.4-10.3-8.8-6.2h10.8zm60.8 0l3.3 10.2H196l-8.6 6.3 3.2 10.2-8.6-6.3-8.7 6.3 3.3-10.3-8.7-6.2h10.8zm60.8 0l3.3 10.2h10.8l-8.7 6.3 3.3 10.2-8.7-6.3-8.7 6.3 3.4-10.3-8.8-6.2h10.8zm60.8 0l3.3 10.2h10.7l-8.6 6.3 3.3 10.2-8.7-6.3-8.7 6.3 3.3-10.3-8.6-6.2h10.7z'
                    />
                  </g>
                </svg>
                <span style={{ color: "black" }}>+1</span>
              </div>
              <input type='tel' placeholder='201-555-0123' disabled />
            </div>
          </div>

          <div class='form-group'>
            <label>
              Email <span class='required'>*</span>
            </label>
            <div class='input-icon'>
              <input type='email' placeholder='john.doe@mail.com' disabled />
              <span class='icon'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                  <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z' />
                </svg>
              </span>
            </div>
          </div>

          <div class='form-group'>
            <label>
              Product Category <span class='required'>*</span>
            </label>
            <div class='select-wrapper'>
              <select className='form-select'>
                <option selected disabled>
                  Select a category
                </option>
              </select>
            </div>
          </div>

          <div class='checkbox-group'>
            <input type='checkbox' id='consent' disabled />
            <label for='consent'>
              I agree to receive text messages at the phone number provided.
            </label>
          </div>

          <button class='btn btn-secondary' disabled>
            Meet Now
          </button>

          <div class='divider'>
            <span>Or</span>
          </div>

          <button class='btn btn-secondary' disabled>
            Schedule For Later
          </button>
        </div>
      </div>
      {/* Request Error & Custom Search Wrapper (if applicable) */}
      <div className='pb-dev-request-error-msg'></div>
      <div
        className='pb-dev-custom-search-wrapper'
        data-requestedFrom={formDataState.pageHandle || ""}
        data-shopid={formDataState.shopId || ""}
        data-shopEmail={formDataState.shopEmail || ""}
        data-shopDomain={formDataState.shopDomain || ""}
        data-shopName={formDataState.shopName || ""}
      ></div>

      {/* Powered By Section */}
      <div className='pb-dev-powered-by-section'>
        <span className='pb-dev-powered-by-text'>Powered by</span>
        <img
          src='https://purebrand.io/static/media/Purebrand.a05df52f03301d4e9159.png'
          alt='Purebrand'
          className='pb-dev-powered-by-logo'
          width='150px'
          height='auto'
        />
      </div>

      {/* Demo Customers Section */}
      <div className='pb-dev-demo-customers'>
        <div className='pb-dev-demo-customer-container'>
          <div className='pb-dev-demo-customer-row'>
            <div className='pb-dev-demo-cust-col-right'>
              <div className='pb-dev-demo-cust-inner-right'>
                <div className='pb-dev-demo-cust-box'>
                  <div className='pb-dev-demo-cust-box-inners'>
                    <div className='pb-dev-demo-imgs'>
                      <img
                        src='//murphydoor.com/cdn/shop/files/Vector_8_1024x1024.png?v=1715937749'
                        className='pb-dev-demo-img-icon'
                        alt=''
                      />
                    </div>
                    <div className='pb-dev-demo-content'>
                      <h2 className='pb-dev-demo-titles'>Transparency</h2>
                      <div className='pb-dev-demo-info'>
                        Take a peak around the curtain with real demos from
                        people just like you.
                      </div>
                    </div>
                  </div>
                </div>

                <div className='pb-dev-demo-cust-box'>
                  <div className='pb-dev-demo-cust-box-inners'>
                    <div className='pb-dev-demo-imgs'>
                      <img
                        src='//murphydoor.com/cdn/shop/files/group-line_1024x1024.png?v=1715937749'
                        className='pb-dev-demo-img-icon'
                        alt=''
                      />
                    </div>
                    <div className='pb-dev-demo-content'>
                      <h2 className='pb-dev-demo-titles'>Face To Face</h2>
                      <div className='pb-dev-demo-info'>
                        What do products look like in their real environments.
                        Purebrand lets you see with no restrictions.
                      </div>
                    </div>
                  </div>
                </div>

                <div className='pb-dev-demo-cust-box'>
                  <div className='pb-dev-demo-cust-box-inners'>
                    <div className='pb-dev-demo-imgs'>
                      <img
                        src='//murphydoor.com/cdn/shop/files/Vector_9_1024x1024.png?v=1715937749'
                        className='pb-dev-demo-img-icon'
                        alt=''
                      />
                    </div>
                    <div className='pb-dev-demo-content'>
                      <h2 className='pb-dev-demo-titles'>Real Products</h2>
                      <div className='pb-dev-demo-info'>
                        See the products you’re looking for in action, and not
                        in the showroom.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopifyDetails;
