import { Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { dots } from 'assets/images';
import BankModal from 'components/Modal/BankModal';
import DotsContext from 'context/DotsContext';
import AuthContext from 'context/AuthContext';
import { Alert, Spinner } from 'react-bootstrap';
import './AdminDots.css';
import { centsToDollars } from 'utils/currencyFormatter';
import { APP_STATUS } from 'utils/constants';
import FundsModal from 'components/Modal/AddFundsModal';
import AppModal from 'components/Modal/CreateAppModal';
import ComplianceModal from 'components/Modal/ComplianceModal';
import moment from 'moment';
import DotsFessAgreement from 'components/Agreements/DotsFessAgreement';
import ProfileContext from 'context/ProfileContext';
import { useLocation, useNavigate } from 'react-router-dom';
/**
 * This component is used to render the dots UI for store admin
 *
 * @return {ReactNode} react element that renders the dots UI for store admin
 */

const AdminDots = () => {

  const {
    getAdminPaymentStatus,
    dotsStoreLoading,
    dotsStoreStatusError,
    dotsStoreInfo,
    dotsStoreAccountInfo,
    dotsStoreComplianceInfo,
    dotsStoreAppInfo,
  } = useContext(DotsContext);
  const { userProfile, user } = useContext(AuthContext);
  const { updateFormData } = useContext(ProfileContext);
  const [bankModalOpen, setBankModalOpen] = useState(false);
  const [fundsModalOpen, setFundsModalOpen] = useState(false);
  const [appModalOpen, setAppModalOpen] = useState(false);
  const [complianceModalOpen, setComplianceModalOpen] = useState(false);
  const [directorsModalOpen, setDirectorsModalOpen] = useState(false);
  const [directors, setDirectors] = useState();
  const [alertNotes, setAlertNotes] = useState('');
// Check if the page is being used as a settings page in the registration flow.
  // For example, StoreDetail redirected here with a resume flag.
  const openComplianceModal = () => {
    setComplianceModalOpen(true);
  };

  const openAppModal = () => {
    setAppModalOpen(true);
  };

  const openBankAccountModal = () => {
    setBankModalOpen(true);
  };

  const handleModalClose = () => {
    setBankModalOpen(false);
  };

  const handleFundsModalClose = () => {
    setFundsModalOpen(false);
  };

  const handleAppModalClose = () => {
    setAppModalOpen(false);
  };

  const handleComplianceModalClose = () => {
    setComplianceModalOpen(false);
  };

  /**
   * This function is used to get the payment info status of the store admin
   *
   * @return {void}
   */

  const getStoreAdminsStatus = async () => {
    try {
      await getAdminPaymentStatus(`?store_id=${userProfile.store.store_id}`);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getStoreAdminsStatus();
  }, []);
  useEffect(() => {
    if (user.role === 2) {
      if (
        !dotsStoreAccountInfo &&
        dotsStoreInfo?.app_approval_status === APP_STATUS.APPROVED
      ) {
        setAlertNotes(
          "Connect the account you wish to use when providing funds to pay your Host Commission payments and your Purebrand fees."
        );
      } else if (!dotsStoreComplianceInfo) {
        setAlertNotes(
          "To ensure secure payment processing and maintain compliance with financial regulations, we need to verify your business information before we can issue payments through our platform. This verification process helps us maintain secure payment processing for all parties, ensure proper 1099 tax documentation for Host payments, and protect against fraudulent activities. Please provide your business details exactly as they appear in public records. Payment processing capabilities will be activated once your information is verified. Note: Incorrect or mismatched information will delay your account approval."
        );
      } else {
        setAlertNotes("");
      }
    }
  }, [dotsStoreAccountInfo, dotsStoreInfo, dotsStoreComplianceInfo]);

  useEffect(() => {
    setDirectors(dotsStoreComplianceInfo?.directors || []);
  }, [dotsStoreComplianceInfo]);

  /**
   * This component renders the wallet info of the store admin
   *
   * @return {ReactNode} React node that renders the wallet info of the store admin
   */

  const getWalletInfo = () => {
    return (
      <div className="wallet-info">
        <div> Your Wallet balance:</div>
        <div className="wallet-amount">
          ${centsToDollars(dotsStoreAppInfo?.metrics?.wallet_balance)}
        </div>
      </div>
    );
  };

  /**
   * This component renders the payment info of the store admin
   *
   * @return {ReactNode} React node that renders the payment info of the store admin
   */

  const getPaymentInfo = () => {
    if (
      !dotsStoreAccountInfo &&
      dotsStoreInfo.app_approval_status === APP_STATUS.APPROVED
    ) {
      return (
        <Button type="primary" onClick={openBankAccountModal}>
          Add Bank Account
        </Button>
      );
    }
  };

  /**
   * This component renders the compliance info of the store admin
   *
   * @return {ReactNode} React node that renders the compliance info of the store admin
   */

  const getComplianceInfo = () => {
    if (!dotsStoreComplianceInfo) {
      return (
        <Button type="primary" onClick={openComplianceModal}>
          Add Compliance Info
        </Button>
      );
    }
  };

  /**
   * This component renders the account info of the store admin
   *
   * @return {ReactNode} React node that renders the account info of the store admin
   */

  const getBankDetails = () => {
    if (dotsStoreAccountInfo) {
      return (
        <div className="bank-details-container">
          <h4>Bank Details:</h4>
          <div className="account-card">
            <div className="account-name">{dotsStoreAccountInfo?.name}</div>
            <div className="account-mask">
              **** **** **** {dotsStoreAccountInfo?.mask}
            </div>
            <div className="update-account">
              <Button type="primary" onClick={openBankAccountModal}>
                Update Bank Account
              </Button>
            </div>
          </div>
        </div>
      );
    }
  };

  /**
   * This component renders the UI based on the payment status of the store admin
   *
   * @return {ReactNode} React node that renders the UI based on the payment status of the store admin
   */

  const renderConditionalUI = () => {
    if (!dotsStoreInfo?.connected_app_id) {
      return getSignupUI();
    } else {
      if (
        dotsStoreInfo?.is_compliance_added &&
        dotsStoreInfo?.app_approval_status !== APP_STATUS.APPROVED
      ) {
        return (
          <div className="wallet-card">
            <div className="wallet-info">
              <div> Your Wallet balance:</div>
              <div className="wallet-amount">
                ${centsToDollars(dotsStoreAppInfo?.metrics?.wallet_balance)}
              </div>
            </div>
            <div>
              {' '}
              <div className="review-text">
                {' '}
                <div>
                  We received your submission on{' '}
                  {dotsStoreInfo?.compliance_added_at
                    ? moment(dotsStoreInfo?.compliance_added_at).format(
                        'Do MMMM, YYYY'
                      )
                    : '-'}
                  . Your account is under review and can take up to 5 business
                  days before approval.
                </div>
                <div>
                  Please contact support@purebrand.io for any questions.
                </div>
              </div>
              {/* <Button type="primary" onClick={openComplianceModal}>
                Update Compliance Info
              </Button> */}
            </div>
          </div>
        );
      }
      return (
        <>
          <div className="wallet-card">
            <div className="wallet-amount-info">{getWalletInfo()}</div>
            <div className="payment-info">{getPaymentInfo()}</div>
            <div className="compliance-info">{getComplianceInfo()}</div>
          </div>
          <div className="bank-details">{getBankDetails()}</div>
        </>
      );
    }
  };

  /**
   * This component renders the dots signup UI of the store admin
   *
   * @return {ReactNode} React node that renders the dots signup UI of the store admin
   */

  const getSignupUI = () => {
    return (
      <div className="store-admin-payment">
        <div className="invite-text">
          Purebrand partners with Dots for payments. Complete the sign up to
          start paying hosts.
        </div>
        <div className="dots-image">
          <img height={32} width={100} src={dots} alt="" />
        </div>
        <div className="admin-sign-up-button">
          <Button onClick={openAppModal} className="admin-sign-up-btn">
            Sign Up With Dots
          </Button>
        </div>
      </div>
    );
  };

  if (dotsStoreStatusError) {
    return (
      <Alert className="mt-1" variant="danger" key={'danger'}>
        {dotsStoreStatusError}
      </Alert>
    );
  }

  if (dotsStoreLoading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-dots">
          <Spinner animation="grow" /> <Spinner animation="grow" />{' '}
          <Spinner animation="grow" />
        </div>
      </div>
    );
  }
  return (
    <div>
      <DotsFessAgreement />
      {alertNotes && (
        <Alert className="mt-1" variant="warning" key={'warning'}>
          {alertNotes}
        </Alert>
      )}
      {renderConditionalUI()}
      <BankModal
        bankModalOpen={bankModalOpen}
        handleModalClose={handleModalClose}
        getStoreAdminsStatus={getStoreAdminsStatus}
      />
      <FundsModal
        fundsModalOpen={fundsModalOpen}
        handleModalClose={handleFundsModalClose}
        getStoreAdminsStatus={getStoreAdminsStatus}
      />
      <AppModal
        appModalOpen={appModalOpen}
        handleModalClose={handleAppModalClose}
        getStoreAdminsStatus={getStoreAdminsStatus}
      />

      {complianceModalOpen && (
        <ComplianceModal
          complianceModalOpen={complianceModalOpen}
          handleModalClose={handleComplianceModalClose}
          getStoreAdminsStatus={getStoreAdminsStatus}
          setDirectorsModalOpen={setDirectorsModalOpen}
          directorsModalOpen={directorsModalOpen}
          directors={directors}
          setDirectors={setDirectors}
          initialStore={userProfile?  userProfile?.store?.store_name: userProfile?.initialStoreName || {}}
          existingUser = {userProfile}
        />
      )}
    </div>
  );
};

export default AdminDots;
