import React, { useContext, useEffect } from "react";

import { BlankImg, Icon, LogoutIcon, Purebrand } from "assets/images";
import "./Sidebar.css";
import Sideitems from "./Sideitems";
import AuthContext from "context/AuthContext";
import NotificationContext from "context/NotificationContext";
import { Alert, Dropdown, Toast, ToastContainer } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import "Pages/dashboard/host/Dashboard/hostdashboard.css";
import { useEffectOnce } from "hooks/useEffectOnce";
import DemoContext from "context/DemoContext";
import ProfileContext from "context/ProfileContext";
import PaymentContext from "context/PaymentContext";
import ImgComponent from "components/imgComponent";
import DataContext from "context/DataContext";

const Sidebaar = (props) => {
  const { user, logoutUser, userProfile, resendVerification, isEmailVerified } =
    useContext(AuthContext);
  const { isCategoriesAdded } =
    useContext(DataContext);
  const { notification, newNotifications, getBellNotification } =
    useContext(NotificationContext);
  let { updateHostRequests } = useContext(ProfileContext);
  const { storeDemoPendingView, userDemos } = useContext(DemoContext);
  const { payToHostlist, cardDetailsHost, userPaymentDetail } =
    useContext(PaymentContext);
  const accountStatus = userProfile?.store?.is_account_connected;

  const [errorMessage, setErrorMessage] = useState(
    "You are not verified. Please check your email to get verified."
  );
  const [loadingNotification, setLoadingNotification] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("all");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [location, setLocation] = useState("welcome");
  const [bellColour, setBellColour] = useState(null);
  let pathLocation = useLocation();

  const checkProfile = async () => {
    await userPaymentDetail();
  };

  useEffect(() => {
    checkProfile();
  }, []);

  const handleLogout = () => {
    const logout = logoutUser();
    logout
      .then(function (response) {
        if (response.status === 200) {
          console.log("logged out");
          window.location.href = `${process.env.REACT_APP_URL}/login`
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        // <Redirect to={"/"} />;
      });
  };

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  const handleDropNotification = (e) => {
    if (e === "Product Approved" && user?.role === 1) {
      navigate("/user/showrooms");
    } else if (e === "Product Review" && user?.role === 2) {
      navigate("/user/host");
    } else if (e === "Demo Requested" && user?.role === 3) {
      navigate("/user/meetings");
    }
  };

  const getnotification = () => {
    return newNotifications.map((x, index) => {
      if (type === "unread") {
        if (!x.is_seen) {
          return (
            <Dropdown.Item
              key={index}
              onClick={() => handleDropNotification(x.title)}
            >
              <p key={index} className="notification-body-menu">
                {x.body}
              </p>
            </Dropdown.Item>
          );
        }
      } else if (type === "read") {
        if (x.is_seen) {
          return (
            <Dropdown.Item
              key={index}
              onClick={() => handleDropNotification(x.title)}
            >
              <p key={index} className="notification-body-menu">
                {x.body}
              </p>
            </Dropdown.Item>
          );
        }
      } else {
        return (
          <Dropdown.Item
            key={index}
            onClick={() => handleDropNotification(x.title)}
          >
            <p key={index} className="notification-body-menu">
              {x.body}
            </p>
          </Dropdown.Item>
        );
      }
      return <></>;
    });
  };
  const pic = userProfile?.profile_picture?.file
    ? userProfile.profile_picture.file
    : "";
  const profilepicURL = pic;

  const handleClickNotification = () => {
    setLoadingNotification(true);
    setBellColour(false);
    const res = getBellNotification("&limit=5");
    if (res) {
      setLoadingNotification(false);
    }
  };
  useEffectOnce(() => {
    setLocation(pathname.split("/").slice(-1)[0]);
  }, [pathname]);

  const onToggleClick = (e) => {
    setIsActive(!isActive);
  };
  const onNavBarLogoPressed = () => {
    if (location === "home") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      navigate("/user/welcome");
    }
  };

  useEffectOnce(() => {
    setShow(true);
    setBellColour(true);
    getBellNotification("&limit=5");
    // user.role === 3 && userDemos();
    if (user?.role === 3) {
      userDemos();
    }
    if (
      user?.role === 2 ||
      user?.role === 4 ||
      user?.role === 5 ||
      user?.role === 6
    ) {
      storeDemoPendingView();
      updateHostRequests(`?order=true&active=false`);
      payToHostlist("?order=true");
    }
  }, [notification]);

  if (user) {
    return (
      <React.Fragment>
        <div className="row main-row-wrapper">
          {pathLocation.pathname === "/user/set-password" ? null : (
            <>
              {" "}
              <div
                onClick={() => setIsActive(!isActive)}
                className={`overlay ${isActive ? "isactive" : ""}`}
              ></div>
              <div
                className={`sidebar-wrapper nav-container  ${
                  isActive ? "isactive" : ""
                }`}
              >
                <div className={`nav-toggle `} onClick={onToggleClick}></div>
                <nav className="sidebar open nav-items">
                  <header>
                    <div className="image-text">
                      <div className="text logo-text">
                        <img
                          onClick={() => onNavBarLogoPressed()}
                          src={Purebrand}
                          className="logo-sidebar"
                          alt="logo"
                        />
                        {user?.role === 2 ? (
                          <p className="user-status">Store Admin</p>
                        ) : (
                          <></>
                        )}
                        {user?.role === 1 ? (
                          <p className="user-status">Purebrand Admin</p>
                        ) : (
                          <></>
                        )}
                        {user?.role === 3 ? (
                          <p className="user-status">Host</p>
                        ) : (
                          <></>
                        )}
                        {user?.role === 4 && (
                          <p className="user-status">Store Admin (Owner)</p>
                        )}
                        {user?.role === 5 && (
                          <p className="user-status">Store Admin (Viewer)</p>
                        )}
                        {user?.role === 6 && (
                          <p className="user-status">
                            Store Admin (Edit Admin)
                          </p>
                        )}
                      </div>
                    </div>
                  </header>

                  <div className="menu-bar">
                    <div className="menu">
                      <ul className="menu-links">
                        <Sideitems setIsActive={setIsActive} />
                      </ul>
                    </div>

                    <div className="bottom-content">
                      <li className="nav-link">
                        <button onClick={handleLogout}>
                          <span className="nav-link">
                            {" "}
                            <img
                              src={LogoutIcon}
                              alt="home"
                              style={{ width: "32px", height: "32px" }}
                            />{" "}
                            Logout
                          </span>
                        </button>
                      </li>
                    </div>
                  </div>
                </nav>
              </div>
            </>
          )}

          <div className="right-content-wrapper">
            <div className="row user-wrapper">
              <div className="col d-flex top-user-wrap">
                <h3>
                  Hello {user?.firstName ? user.firstName : ""}{" "}
                  {user?.lastName ? user.lastName.charAt(0) : ""}.
                </h3>
              </div>
              {pathLocation.pathname === "/user/set-password" ? null : (
                <div className="col d-flex user-image-wrap">
                  <Dropdown
                    onClick={handleClickNotification}
                    className="bell-icon"
                  >
                    <Dropdown.Toggle
                      className=" d-flex"
                      variant="image"
                      id="dropdown-basic"
                    >
                      {bellColour === true ? (
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          id="_24x24_On_Light_Notification-Alert"
                          data-name="24x24/On Light/Notification-Alert"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            id="view-box"
                            width="24"
                            height="24"
                            fill="none"
                          />
                          <path
                            id="Shape"
                            d="M6,17v-.5H2.25A2.253,2.253,0,0,1,0,14.25v-.382a2.542,2.542,0,0,1,1.415-2.289A1.247,1.247,0,0,0,2.1,10.572l.446-4.91A6.227,6.227,0,0,1,10.618.286a5.477,5.477,0,0,0-.635,1.374A4.794,4.794,0,0,0,8.75,1.5,4.7,4.7,0,0,0,4.045,5.8L3.6,10.708A2.739,2.739,0,0,1,2.089,12.92a1.055,1.055,0,0,0-.589.949v.382A.751.751,0,0,0,2.25,15h13A.751.751,0,0,0,16,14.25v-.382a1.053,1.053,0,0,0-.586-.948A2.739,2.739,0,0,1,13.9,10.708l-.2-2.18a5.473,5.473,0,0,0,1.526.221l.166,1.822a1.26,1.26,0,0,0,.686,1.005,2.547,2.547,0,0,1,1.418,2.29v.382a2.252,2.252,0,0,1-2.25,2.25H11.5V17A2.75,2.75,0,0,1,6,17Zm1.5,0A1.25,1.25,0,0,0,10,17v-.5H7.5ZM15.047,6.744A3.486,3.486,0,0,1,13.5,6.28L13.456,5.8a4.7,4.7,0,0,0-1.648-3.185,3.5,3.5,0,0,1,.61-1.417A6.221,6.221,0,0,1,14.95,5.662l.1,1.081v0Z"
                            transform="translate(3.25 2.25)"
                            fill="#141124"
                          />
                          <path
                            id="Shape-2"
                            data-name="Shape"
                            d="M3.5,7A3.5,3.5,0,1,1,7,3.5,3.5,3.5,0,0,1,3.5,7Z"
                            transform="translate(15 2)"
                            fill="#ff6359"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          id="_24x24_On_Light_Notification"
                          data-name="24x24/On Light/Notification"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            id="view-box"
                            width="24"
                            height="24"
                            fill="none"
                          />
                          <path
                            id="Shape"
                            d="M6,17v-.5H2.25A2.253,2.253,0,0,1,0,14.25v-.382a2.542,2.542,0,0,1,1.415-2.289A1.248,1.248,0,0,0,2.1,10.572l.446-4.91a6.225,6.225,0,0,1,12.4,0l.446,4.91a1.26,1.26,0,0,0,.686,1.005,2.547,2.547,0,0,1,1.418,2.29v.382a2.252,2.252,0,0,1-2.25,2.25H11.5V17A2.75,2.75,0,0,1,6,17Zm1.5,0A1.25,1.25,0,0,0,10,17v-.5H7.5ZM4.045,5.8,3.6,10.708A2.738,2.738,0,0,1,2.089,12.92a1.055,1.055,0,0,0-.589.949v.382A.751.751,0,0,0,2.25,15h13A.751.751,0,0,0,16,14.25v-.382a1.054,1.054,0,0,0-.586-.948A2.739,2.739,0,0,1,13.9,10.708L13.456,5.8a4.725,4.725,0,0,0-9.411,0Z"
                            transform="translate(3.25 2.25)"
                            fill="#141124"
                          />
                        </svg>
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="notification-menu-box">
                      <div className="notification-menu-wrapper">
                        <nav className="nav">
                          <ul className="notification-nav">
                            <li
                              className={`nav-link ${
                                type === "all" ? "active-selection" : ""
                              }`}
                              onClick={() => setType("all")}
                            >
                              All
                            </li>
                            <li
                              className={`nav-link ${
                                type === "unread" ? "active-selection" : ""
                              }`}
                              onClick={() => setType("unread")}
                            >
                              Unread
                            </li>
                            <li
                              className={`nav-link ${
                                type === "read" ? "active-selection" : ""
                              }`}
                              onClick={() => setType("read")}
                            >
                              Read
                            </li>
                          </ul>
                        </nav>
                      </div>
                      {loadingNotification ? (
                        <Dropdown.Item>Loading list</Dropdown.Item>
                      ) : (
                        <></>
                      )}
                      {getnotification("all")}
                      <Dropdown.Item
                        onClick={() => navigate("/user/notifications")}
                        className="view-all-notification"
                      >
                        View all
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle
                      className=" d-flex"
                      variant="text"
                      id="dropdown-basic"
                    >
                      <ImgComponent
                        src={
                          isValidUrl(profilepicURL) ? profilepicURL : BlankImg
                        }
                        alt="profile"
                        className="photo-profile"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigate("/user/profile")}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigate("/user/change-password")}
                      >
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
            {notification.title === "" ? (
              <></>
            ) : (
              <ToastContainer className="p-3" position="bottom-end">
                <Toast
                  onClose={() => setShow(false)}
                  show={show}
                  delay={10000}
                  autohide
                  animation
                  // style={{
                  //   position: "absolute",
                  //   top: 100,
                  //   right: 20,
                  //   minWidth: 200,
                  // }}
                >
                  <Toast.Header>
                    <img
                      src={Icon}
                      style={{ height: "20px", width: "20px" }}
                      className="rounded me-2"
                      alt=""
                    />
                    <strong className="me-auto">{notification.title}</strong>
                    <small className="text-muted">just now</small>
                  </Toast.Header>
                  <Toast.Body>{notification.body}</Toast.Body>
                </Toast>
              </ToastContainer>
            )}

            {
              // TODO: This might be needed in future if it is required to keep this condition in a toast at the top of the layout
            }

            {/* {dotsStatus !== DOTS_CONNECT_STATUS.COMPLETE_FLOW &&
              user?.role === 3 && (
                <div className="not-verified-wrapper">
                  <Alert className="d-flex" key={"danger"} variant={"danger"}>
                    <p>
                      You have not completed your Payment Flow.
                      <span
                        onClick={() => {
                          navigate("settings");
                        }}
                        className="clicker-paragraph"
                      >
                        Click here{" "}
                      </span>
                      to complete your payment onboarding flow.
                    </p>
                  </Alert>
                </div>
              )} */}

            {!isEmailVerified ? (
              <div className="not-verified-wrapper">
                <Alert className="d-flex" key={"danger"} variant={"danger"}>
                  <p>
                    {errorMessage}{" "}
                    <span
                      onClick={() => {
                        const response = resendVerification();
                        response.then(function (response) {
                          if (response.status === 200) {
                            setErrorMessage("Check your email.");
                          }
                        });
                      }}
                      className="clicker-paragraph"
                    >
                      {" "}
                      Click here{" "}
                    </span>
                    to get the email again.
                  </p>
                </Alert>
              </div>
            ) : (
              <></>
            )}
            {!accountStatus && user?.role === 2  ? (
              <div className="not-verified-wrapper">
                <Alert className="d-flex" key={"danger"} variant={"danger"}>
                  <p>
                    You have not connected your dots account. Visit the Payment Setup in settings page to set it up{" "}or {" "}
                    <span
                      onClick={() => {
                        navigate("/user/settings?page=adminDots");
                        
                      }}
                      className="clicker-paragraph"
                    >
                      {" "}
                      Click here{" "}
                    </span>
                  </p>
                </Alert>
              </div>
            ) : (
              <></>
            )}

            {!isCategoriesAdded ? (
              <div className="not-verified-wrapper">
                <Alert className="d-flex" key={"danger"} variant={"danger"}>
                  <p>
                    You have a new product in your Purebrand account that needs to be assigned to a category. {" "}
                    <span
                      onClick={() => {
                        navigate("/user/productsList")
                      }}
                      className="clicker-paragraph"
                    >
                      {" "}
                      Click here{" "}
                    </span>
                    to assign.
                  </p>
                </Alert>
              </div>
            ) : (
              <></>
            )}
            <Outlet />
            {/* {props.children} */}
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default Sidebaar;
