import Dashelement from "components/Dashboard/Dashelement";
import ApiContext from "context/ApiContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useContext } from "react";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import states from "components/Data/states.json";
import "./profileform.css";
import { base64Decode } from "@firebase/util";
import AuthContext from "context/AuthContext";
import GetTimer from "utils/getTimer";

const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;
export const blankRegExp = /(?!^$)([^\s])/;
const FormValidate = Yup.object().shape({
  about_me: Yup.string()
    .required("This field is mandatory")
    .min(25, "Must be at least 25 characters long")
    .matches(blankRegExp, "No white spaces only"),
  phone_number: Yup.string().required("This field is mandatory"),
  address: Yup.string().required("This field is mandatory"),
  city: Yup.string().required("This field is mandatory"),
  state: Yup.string().required("This field is mandatory"),
  zip_code: Yup.string()
    .required("This field is mandatory")
    .matches(zipRegExp, "Valid zip= 10XXX or 10XXX-XXXX."),
  social_selected: Yup.bool().isTrue(),
  instagram_handle: Yup.string().matches(blankRegExp, "No white spaces only"),
  tiktok_handle: Yup.string().matches(blankRegExp, "No white spaces only"),
  twitter_handle: Yup.string().matches(blankRegExp, "No white spaces only"),
  facebook_handle: Yup.string().matches(blankRegExp, "No white spaces only"),
});

const BasicInfo = (props) => {
  const { user } = useContext(AuthContext);
  let { getGeoCoords, getUserOtp, putUserOtp } = useContext(ApiContext);
  const [error, setError] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [verifiedOtp, setVerifiedOtp] = useState(false);
  const [resMessage, setResMessage] = useState(null);
  const [resOtp, setResOtp] = useState(null);
  const [sentOtp, setSentOtp] = useState(false);

  const handleSubmit = (values) => {
    const response = getGeoCoords(values);
    response
      .then(function (response) {
        if (response.status === 200 && response.data.results.length > 0) {
          values.latitude = response.data.results[0].geometry.location.lat;
          values.longitude = response.data.results[0].geometry.location.lng;
          props.next(values);
        } else {
          // TODO: need to uncomment this once we have google maps api ready.
          // setError(true);
          props.next(values);
        }
      })
      .catch(function (error) {
        setError(true);
      });
  };

  const handleOtpTime = () => {
    setSentOtp(true);
    setTimeout(() => {
      setSentOtp(false);
    }, 3 * 60 * 1000);
  };

  const handleOTP = (e) => {
    // setOtpShow(true);

    const data = {
      number: e,
    };
    const res = getUserOtp(data);
    res
      .then(function (response) {
        if (response.status === 200) {
          handleOtpTime();
          setOtpShow(true);
          setResOtp(response.data.data);
          setResMessage(
            "Check your phone for a verification code and enter it below."
          );
        }
      })
      .catch(function (error) {
        setResMessage("Error sending OTP.");
        console.log(error);
      });
  };

  const checkOTP = (e, num) => {
    const data = {
      number: num,
    };
    setResMessage("Checking...");
    var code = base64Decode(resOtp.code);
    if (e === code) {
      const res = putUserOtp(data, user.uid, `?verify=true`);
      res
        .then(function (response) {
          setResMessage("Successfully verified");
          setVerifiedOtp(true);
          setOtpShow(false);
        })
        .catch(function (error) {
          setResMessage("Verification failed");
          setTimeout(() => {
            setResMessage(null);
          }, 5000);
          console.log(error);
        });
    } else {
      setResMessage("Otp verification failed");
      setTimeout(() => {
        setResMessage(null);
      }, 5000);
    }
  };

  return (
    <Formik
      initialValues={props.data}
      onSubmit={handleSubmit}
      validationSchema={FormValidate}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <Dashelement className="features basic-info-wrapper">
            <div className="container">
              <div className="information-text-wrapper">
                <h3>Personal Information</h3>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6 ">
                  <label className="mb-3">
                    <strong>Address Information</strong>
                  </label>
                  <div className="basic-informations">
                    <div className="row">
                      <div className="col-sm-12 col-md-12  mb-3">
                        <Field
                          type="text"
                          placeholder="Address (15 West Drive St)"
                          name="address"
                          className={`form-control ${
                            touched.address && errors.address
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="address"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-12  mb-3">
                        <Field
                          type="text"
                          placeholder="City"
                          name="city"
                          className={`form-control ${
                            touched.city && errors.city ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="city"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-sm-12 col-md-8  mb-3">
                        <Field
                          as="select"
                          name="state"
                          className={`form-select ${
                            touched.state && errors.state ? "is-invalid" : ""
                          }`}
                        >
                          <option value="" disabled>
                            State
                          </option>
                          {states.map((state, index) => (
                            <option key={index} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="state"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-sm-12 col-md-4  mb-3">
                        <Field
                          type="text"
                          placeholder="Zip Code"
                          name="zip_code"
                          className={`form-control ${
                            touched.zip_code && errors.zip_code
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="zip_code"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <label>
                    <strong>About me</strong>
                  </label>
                  <div className="about-me-wrapper">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <Field
                          as="textarea"
                          rows={3}
                          name="about_me"
                          placeholder="Tell us more about yourself"
                          className={`form-control ${
                            touched.about_me && errors.about_me
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="about_me"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="opt-for-text-wrapper d-flex mt-3">
                    <div className="checkbox">
                      <Field id="checkbox" type="checkbox" name="optIn" />
                      <label htmlFor="checkbox">
                        Send Me Demo Requests Via Text
                      </label>
                      <div className="information-text-sms">
                        <p>
                          By entering your number, you agree to receive text
                          messages at the phone number provided.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <label className="mb-3">
                    <strong>Social information</strong>
                  </label>
                  <div className="">
                    <label>Phone number</label>
                    <div className="form-outline d-flex">
                      <div className="col col-8">
                        <PhoneInput
                          country={"us"}
                          value={values.phone_number}
                          onChange={(e) => setFieldValue("phone_number", e)}
                          className={` ${
                            touched.phone_number && errors.phone_number
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="phone_number"
                          className="invalid-feedback"
                        />
                      </div>
                      {values.optIn ? (
                        <div className="col col-4">
                          {verifiedOtp ? (
                            <div> &#10004; Verified</div>
                          ) : (
                            <>
                              {sentOtp ? (
                                <div className="send-otp-wrap">
                                  Send again in <GetTimer />
                                </div>
                              ) : (
                                <div
                                  onClick={() => handleOTP(values.phone_number)}
                                  className="otp-btn-wrap btn btn-primary"
                                >
                                  Send OTP
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="response-otp-message">
                      <p>{resMessage ? resMessage : ""}</p>
                    </div>

                    {otpShow ? (
                      <div className="d-flex">
                        <div className="col col-md-4 mt-1 mb-2">
                          <Field
                            type="text"
                            placeholder="OTP"
                            name="otpCode"
                            className={`form-control `}
                          />
                        </div>
                        <div className="col col-4 mt-1 mb-2">
                          <div
                            onClick={() =>
                              checkOTP(values.otpCode, values.phone_number)
                            }
                            className="btn send-otp-button"
                          >
                            Verify OTP
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="social media wrapper">
                    <p>
                      What is your social media?{" "}
                      <span
                        className={` ${
                          errors.social_selected ? "red-error" : ""
                        }`}
                      >
                        (enter at least one)
                      </span>
                    </p>
                    <div className="form-outline mb-3 d-flex">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.75 4.50781C7.25 4.50781 5.25781 6.53906 5.25781 9C5.25781 11.5 7.25 13.4922 9.75 13.4922C12.2109 13.4922 14.2422 11.5 14.2422 9C14.2422 6.53906 12.2109 4.50781 9.75 4.50781ZM9.75 11.9297C8.14844 11.9297 6.82031 10.6406 6.82031 9C6.82031 7.39844 8.10938 6.10938 9.75 6.10938C11.3516 6.10938 12.6406 7.39844 12.6406 9C12.6406 10.6406 11.3516 11.9297 9.75 11.9297ZM15.4531 4.35156C15.4531 4.9375 14.9844 5.40625 14.3984 5.40625C13.8125 5.40625 13.3438 4.9375 13.3438 4.35156C13.3438 3.76562 13.8125 3.29688 14.3984 3.29688C14.9844 3.29688 15.4531 3.76562 15.4531 4.35156ZM18.4219 5.40625C18.3438 4 18.0312 2.75 17.0156 1.73438C16 0.71875 14.75 0.40625 13.3438 0.328125C11.8984 0.25 7.5625 0.25 6.11719 0.328125C4.71094 0.40625 3.5 0.71875 2.44531 1.73438C1.42969 2.75 1.11719 4 1.03906 5.40625C0.960938 6.85156 0.960938 11.1875 1.03906 12.6328C1.11719 14.0391 1.42969 15.25 2.44531 16.3047C3.5 17.3203 4.71094 17.6328 6.11719 17.7109C7.5625 17.7891 11.8984 17.7891 13.3438 17.7109C14.75 17.6328 16 17.3203 17.0156 16.3047C18.0312 15.25 18.3438 14.0391 18.4219 12.6328C18.5 11.1875 18.5 6.85156 18.4219 5.40625ZM16.5469 14.1562C16.2734 14.9375 15.6484 15.5234 14.9062 15.8359C13.7344 16.3047 11 16.1875 9.75 16.1875C8.46094 16.1875 5.72656 16.3047 4.59375 15.8359C3.8125 15.5234 3.22656 14.9375 2.91406 14.1562C2.44531 13.0234 2.5625 10.2891 2.5625 9C2.5625 7.75 2.44531 5.01562 2.91406 3.84375C3.22656 3.10156 3.8125 2.51562 4.59375 2.20312C5.72656 1.73438 8.46094 1.85156 9.75 1.85156C11 1.85156 13.7344 1.73438 14.9062 2.20312C15.6484 2.47656 16.2344 3.10156 16.5469 3.84375C17.0156 5.01562 16.8984 7.75 16.8984 9C16.8984 10.2891 17.0156 13.0234 16.5469 14.1562Z"
                          fill="#111827"
                        />
                      </svg>
                      <div className="">
                        <Field
                          type="text"
                          name="instagram_handle"
                          placeholder="username"
                          onChange={(event) => {
                            setFieldValue(
                              "instagram_handle",
                              event.target.value
                            );
                            if (event.target.value !== "") {
                              setFieldValue("social_selected", true);
                            } else {
                              if (
                                event.target.value === "" &&
                                values.twitter_handle === "" &&
                                values.tiktok_handle === "" &&
                                values.facebook_handle === ""
                              ) {
                                setFieldValue("social_selected", false);
                              }
                            }
                          }}
                          className={`form-control ${
                            touched.instagram_handle && errors.instagram_handle
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="instagram_handle"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="form-outline mb-3 d-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 19 18"
                        fill="currentColor"
                        class="bi bi-twitter-x"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>
                      <div>
                        <Field
                          type="text"
                          name="twitter_handle"
                          placeholder="username"
                          onChange={(event) => {
                            setFieldValue("twitter_handle", event.target.value);
                            if (event.target.value !== "") {
                              setFieldValue("social_selected", true);
                            } else {
                              if (
                                event.target.value === "" &&
                                values.instagram_handle === "" &&
                                values.tiktok_handle === "" &&
                                values.facebook_handle === ""
                              ) {
                                setFieldValue("social_selected", false);
                              }
                            }
                          }}
                          className={`form-control ${
                            touched.twitter_handle && errors.twitter_handle
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="twitter_handle"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="form-outline mb-3 d-flex">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 20 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.5 8.20312C17.7422 8.24219 16.1016 7.69531 14.6953 6.67969V13.6719C14.6953 18.5547 9.38281 21.6016 5.16406 19.1797C0.945312 16.7188 0.945312 10.625 5.16406 8.16406C6.375 7.46094 7.82031 7.1875 9.22656 7.38281V10.8984C7.07812 10.1953 5.00781 12.1094 5.47656 14.2969C5.98438 16.4844 8.64062 17.3438 10.3203 15.8203C10.9062 15.2734 11.2578 14.4922 11.2578 13.6719V0H14.6953C14.6953 0.3125 14.6953 0.585938 14.7734 0.898438C15.0078 2.1875 15.75 3.32031 16.8828 4.02344C17.625 4.53125 18.5625 4.80469 19.5 4.80469V8.20312Z"
                          fill="#111827"
                        />
                      </svg>
                      <div>
                        <Field
                          type="text"
                          name="tiktok_handle"
                          placeholder="username"
                          onChange={(event) => {
                            setFieldValue("tiktok_handle", event.target.value);
                            if (event.target.value !== "") {
                              setFieldValue("social_selected", true);
                            } else {
                              if (
                                event.target.value === "" &&
                                values.instagram_handle === "" &&
                                values.twitter_handle === "" &&
                                values.facebook_handle === ""
                              ) {
                                setFieldValue("social_selected", false);
                              }
                            }
                          }}
                          className={`form-control ${
                            touched.tiktok_handle && errors.tiktok_handle
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="tiktok_handle"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="form-outline mb-3 d-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="35"
                        height="35"
                      >
                        <path
                          fill="#039be5"
                          d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
                        />
                        <path
                          fill="#fff"
                          d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
                        />
                      </svg>
                      <div>
                        <Field
                          type="text"
                          name="facebook_handle"
                          placeholder="username"
                          onChange={(event) => {
                            setFieldValue(
                              "facebook_handle",
                              event.target.value
                            );
                            if (event.target.value !== "") {
                              setFieldValue("social_selected", true);
                            } else {
                              if (
                                event.target.value === "" &&
                                values.instagram_handle === "" &&
                                values.twitter_handle === "" &&
                                values.tiktok_handle === ""
                              ) {
                                setFieldValue("social_selected", false);
                              }
                            }
                          }}
                          className={`form-control ${
                            touched.facebook_handle && errors.facebook_handle
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="facebook_handle"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="error-wrapper">
                {error ? (
                  <p>Some error occured, please check your address {error}</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Dashelement>
          <div className="button-place text-end">
            <button className="btn btn-primary" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BasicInfo;
